import React, { useEffect, useState } from 'react';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';
import { Card, CardActionArea, CardContent } from '@material-ui/core';
import { Grid, Typography, Tooltip, IconButton } from '@material-ui/core';

//
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import moment from 'moment'
//
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

//
export default function RecordingsCDR() {
  const classes = useStyles();
  //
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  var todayDate =new Date().toJSON().slice(0,10);

  const [date, setDate] = useState(todayDate);
  const [phoneNo, setphoneNo] = useState('');

  const [statuOfData, setStatuOfData] = useState('');
//
const [openAlert, setOpenAlert] = useState(false);
const [alertSeverity, setAlertSeverity] = useState("info");
const [alertColor, setAlertColor] = useState("info");
const [modalMsg, setModalMsg] = useState("");
var vertical = "top";
var horizontal = "center";

//
  var url = '/cdr/recordings/getUpdatedRecordsDatePhone';
  var urlLocal =
    'http://192.168.4.44:8089/recordings/getUpdatedRecordsDatePhone';
    const showProfile = (data) => {
      console.log(data.row)
  }

  function clickFetchRecs() {
    if (phoneNo != '' && date != '') {
      if(phoneNo.length < 10){
        // alert('')
        setOpenAlert(true)
        setAlertSeverity('error')
        setAlertColor('error')
        setModalMsg('Phone Number length must be greater then 10')
      }else{
        var newPhone = '4' + phoneNo;
        fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            date_of_call: date + 'T00:00:00.000+00:00',
            prefix_phone_no: newPhone
          })
        })
          .then(res => res.json())
          .then(async result => {
            if (result.data.length > 0) {
              let i = 0;
              result.data.map(ele => {
                i = i + 1;
                return (ele.id = i);
              });
              // result.data.map((ele)=>{
              //   return
              // })
            }
            console.log(result.data)
            await setItems(result.data);
            setStatuOfData('Showing Data with Date and Phone filters')
            setOpenAlert(true)
            setAlertSeverity('success')
            setAlertColor('success')
            setModalMsg('Showing Data with Date and Phone filters')
          });
      }

    } else if (phoneNo === '' && date != '') {
      // alert('Phone field not inserted');

      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          date_of_call: date + 'T00:00:00.000+00:00'
        })
      })
        .then(res => res.json())
        .then(async result => {
          
          if (result.data.length > 0) {
            let i = 0;
            result.data.map(ele => {
              i = i + 1;
              // if(ele.path.endsWith("gsm")){
              //   ele.format="gsm"
              // }else{
              //   ele.format ="N/A"
              // }
              return (ele.id = i);
            });
            result.data.map((ele)=>{
              console.log(ele,"i am here")
              ele.date_of_call = new Date(ele.date_of_call)
              ele.date_of_call = moment(ele.date_of_call).format('LLL')
               return ele
            })
          }
          console.log(result.data)
          await setItems(result.data);
          setStatuOfData('Showing Data with only Date Filter')
          setOpenAlert(true)
          setAlertSeverity('success')
          setAlertColor('success')
          setModalMsg('Showing Data with only Date Filter')
        });
    } else if (phoneNo != '' && date === '') {
      // alert('Date field not inserted');
      if(phoneNo.length < 10){
        alert('Enter Phone No length less then 10')
      }else{
        var newPhone = '4' + phoneNo;

        fetch(url, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            prefix_phone_no: newPhone
          })
        }).then(async result => {
          if (result.data.length > 0) {
            let i = 0;
            result.data.map(ele => {
              i = i + 1;
              return (ele.id = i);
            });
           
          }
          await setItems(result.data);
          setStatuOfData('Showing Data with only Phone Number Filter')
          setOpenAlert(true)
          setAlertSeverity('success')
          setAlertColor('success')
          setModalMsg('Showing Data with only Phone Number Filter')
        });
      }

    } else {
      alert('insert fields');
    }
  }
  function spliceString(str) {
    var spliceStr = str.slice(22);
    return spliceStr;
  }


  function onClickDownload(path){
    window.location.href  = `http://192.168.4.44/duraflex${path}`
  }


  const profilesColumns = [
    {
      headerName: 'Date',
      field: 'date_of_call',
      flex: 0.5
    },
    {
      headerName: 'Call Type',
      field: 'call_type',
      flex: 0.5
    },
  
    {
      headerName: 'Phone Number',
      field: 'phone_no',
      flex: 0.5
    },
    {
      headerName: 'Action',
      field: 'complete_path',

      renderCell: rowData => (


        <>
            <a href="javascript:void(0)" target="_blank" onClick= {() => onClickDownload(spliceString(rowData.row.complete_path))} download>Recording </a>
        </>



      ),
      flex: 0.3
  },
    
  
  ];
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          margin: 20,
          textAlign: 'center'
        }}
      >
        <div style={{ border: '1px dotted black', padding: 30 }}>
          <div style={{ marginBottom: 10 }}>
            <input
              style={{
                minWidth: 200,
                maxWidth: 200,
                minHeight: 35,
                maxHeight: 50,
                border: '2px solid black'
              }}
              type="date"
              value={date}
              onChange={e => setDate(e.target.value)}
            />
            <br />
            {/* {date} */}
          </div>
          <div style={{ marginBottom: 10 }}>
            <input
              style={{
                minWidth: 200,
                maxWidth: 200,
                minHeight: 35,
                maxHeight: 50,
                border: '2px solid black',
                cursor: 'auto'
              }}
              placeholder='Phone Number'
              type="number"
              value={phoneNo}
              onChange={e => setphoneNo(e.target.value)}
            />
            <br />
            {/* {phoneNo} */}
          </div>

          <input
            style={{
              minWidth: 200,
              maxWidth: 200,
              minHeight: 35,
              maxHeight: 50,
            
              border: '2px dashed black',
              backgroundColor: '#8bc34a',
              cursor: 'pointer'
            }}
            type="submit"
            onClick={() => clickFetchRecs()}
          />
          <div style={{ marginTop: '10px' }}>
            {Object.keys(items).length === 0
              ? 'No Records to show'
              : 'Records Available'}
              <br/>
              <p style={{fontWeight: "bold"}}>{statuOfData}</p>
          </div>
        </div>
      </div>

      <Grid container spacing="3">
        <Grid item xs={12} md={12} lg={12}>
          {/*  */}
          <Card>
            <CardContent>
              <DataGrid
              rows={items}
              columns={profilesColumns}
              pageSize={5}
              // rowsPerPageOptions={[10, 20, 50]}

              autoHeight="true"
              pagination
            />
            </CardContent>
          </Card>
          
        </Grid>
      </Grid>
      <Snackbar
        open={openAlert}
        autoHideDuration={2000}
        onClose={() => setOpenAlert(false)}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
      <Alert
          onClose={() => setOpenAlert(false)}
          severity={alertSeverity}
          color={alertColor}
        >
          {modalMsg}
        </Alert>
      </Snackbar>

    </>
  );
}
