export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const SET_ACCOUNT_TYPE = 'SET_ACCOUNT_TYPE';
export const ADMIN = 'ADMIN';
export const MANAGER = 'MANAGER';
export const AREAMANAGER = 'AREAMANAGER';
export const USER = 'USER';
export const COMMON = 'COMMON';
export const SET_ACTIVATED_ROUTE = 'SET_ACTIVATED_ROUTE';
export const SET_NEW_CRUMB = 'SET_NEW_CRUMB';
export const SET_URL_MATCH_FOUND = 'SET_URL_MATCH_FOUND';
export const SET_AGENT_CURRENT_STATUS = 'SET_AGENT_CURRENT_STATUS';
export const SET_SEARCH_DISTRIBUTOR = 'SET_SEARCH_DISTRIBUTOR';
export const SET_ACCESS_FOR_ROLE = 'SET_ACCESS_FOR_ROLE';
