import React, { useEffect, useState } from 'react';
import DispositionForm from '../../../components/DispositionForm';
import { Grid, Card, CardHeader, Button, Snackbar, makeStyles,
  Typography,Box } from '@material-ui/core';
// import PendingCallList from './PendingCallList';
import RecentFiveRecords from '../../../components/RecentFiveRecords';
import RecentFiveRecords1 from 'src/modules/dashboard-360/components/RecentFiverecords1.js';

import LeadButtons from '../../../components/LeadButtons';
import CustomerDetails from '../../../components/CustomerDetails';
import CustomerDetails1 from 'src/modules/dashboard-360/components/customerDetails1.js'
import MuiAlert from '@material-ui/lab/Alert';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useStopwatch } from 'react-timer-hook';
import RecentCustomerOrderDetails from 'src/modules/dashboard-360/components/RecentCustomerOrderDetails';
import CallIcon from '@material-ui/icons/Call';
import {AGENT_SERVICE,AMI,CAMPAIGN} from 'src/modules/dashboard-360/utils/endpoints'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => {
  return {
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    panelBody: {
      padding: 0
    },
    dialogActions: {
      padding: '0 1.5rem 1rem'
    },
    modal: {
      alignItems: 'center',
      width: '100%',
      height: '100%'
    },
    timerComp1: {
      position: 'relative',
      // top: 0,
      // left: '55%',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '1.2rem',
      backgroundColor: theme.palette.secondary.light,
      padding: '8px 10px',
      borderBottomLeftRadius: 8,
      borderBottomRightRadius: 8,
      top : 0,
      left: '55%'
    },
    callWrapper1: {
      left: 'calc(55% + 90px)'
    },
    callInbound1: {
      backgroundColor: theme.palette.success.light
    },
    callOutbound: {
      backgroundColor: theme.palette.secondary.light
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: theme.spacing(1, 1)
    }
  };
});

const Dashboard = () => {
  const classes = useStyles();
  const userData = useSelector(state => state.userData);
  const [formDisabled, setFormDisabled] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [, setTimer] = useState(0);
  const [lastFiveRecords, setLastFiveRecords] = useState(null);
  const [secondsLeft, setSecondsLeft] = useState(0);
  const [timeEnabled, setTimeEnabled] = useState(false);
  const [orders,setOrders] = useState([])
  const [orders1,setOrders1] = useState([])
  const [disable,setDisable]= useState(false)
  const [currentRecord,setCurrentrecord] = useState(null)
  const [lastfi,setlastfi] = useState(null)
  const handleBreakTimeOut = () => {
    //console.log("i am called")
    setTimeEnabled(!timeEnabled);
  };

  const [currentCall, setCurrentCall] = useState({
    callStatus: '',
    callUniqueId: '',
  });

  function setCurrentCallDetails(
    callStatus,
  ) {
    setCurrentCall({
      callStatus,
    });
    localStorage.setItem('callStatus', callStatus);
    if(callStatus === 'AgentConnect' || callStatus === 'AgentComplete'){
      setDisable(true)
    }else{
      
      setDisable(false)
      setFormDisabled(true)
      
    }
  }

  function getAgentCallStatus(agentSipID) {
    //console.log('calling the', agentSipID);

    var axios = require('axios');

    var config = {
      method: 'get',
      url: `${AGENT_SERVICE}/agents/${agentSipID}`,
      headers: {}
    };

    axios(config)
      .then(function (response) {
        // console.log((response.data));
        // console.log(response.data)
        setCurrentCallDetails(
          response.data.Event,
          localStorage.setItem('Interaction_id', response.data.InteractionID),
          localStorage.setItem('CallerNumber', response.data.CallerIDNum),
          
        )
        if (response.data.Paused === '1') {
          localStorage.setItem('Break_Status', 'IN')
        } else {
          localStorage.setItem('Break_Status', 'OUT')
        }
        if(response.data.Event === 'AgentComplete' ){
          setFormDisabled(false)
        }
        if(response.data.Event === 'AgentConnect' ){
          setFormDisabled(false)
        }
        
        let oldQueue = localStorage.getItem('Queue');
        let newQueue = response.data.Queue;
        if(oldQueue != newQueue){
          
          const data1 =''
          var config = {
          method: 'get',
          url: `${AMI}/actions/addq?Queue=${newQueue}&Interface=${localStorage.getItem("AgentSIPID1")}`,
          headers: {},
          data: data1
        };


      axios(config)
        .then(function (response) {
          console.log(response.data, "queue addedd");
        })
        .catch(function (error) {
          console.log(error, "error in adding queue");
          // window.location.reload()
          
        });
        }
        localStorage.setItem('Queue', response.data.Queue)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function breakService() {
    //console.log("called from dispos")
    const AgentSIPID = localStorage.getItem('AgentSIPID1')
    if (localStorage.getItem('Break_Status') === 'OUT') {
      var axios = require('axios');
      var config = {
        method: 'get',
        url: `${AMI}/actions/break?Queue=${localStorage.getItem('Queue')}&Interface=${AgentSIPID}&Reason=BREAKIN&Break=true`,
        headers: {}
      };

      axios(config)
        .then(function (response) {
          console.log((response.data));
          handleBreakTimeOut()
        })
        .catch(function (error) {
          console.log(error.message);
        });
    } else {
      var axios = require('axios');
      var config = {
        method: 'get',
        url: `${AMI}/actions/break?Queue=${localStorage.getItem('Queue')}&Interface=${AgentSIPID}&Reason=BREAKOUT&Break=false`,
        headers: {}
      };

      axios(config)
        .then(function (response) {
          console.log((response.data));
          handleBreakTimeOut()
        })
        .catch(function (error) {
          console.log(error);
        });
    }

  }

  const getLastThreeOrders =()=>{
    const data ={
      'resId' : currentRecord.restaurantID,
      'phone' : `91${currentRecord.phone}`
      // "resId": "5f40c119de781b44102cc42b",
      // "phone": "919500974435"
    }
      axios.post(`/crm-route/lastthreeinteractions?mobilenumber=${localStorage.getItem('CallerNumber')}`,data)
      .then((response)=>{
        console.log(response.data,"last three orders")
        setOrders(response.data)
      })
      .catch((err)=>{
        console.log("error in fetching last three orders",err.message)
      })
  }

  const getLastFiveInteractions =()=>{
    axios.get(`${CAMPAIGN}/campaign/lastfiveinteractions?mobilenumber=${localStorage.getItem('CallerNumber')}`)
      .then((response1)=>{
          console.log(response1.data,"last five interactions")
          let id = 0;
          response1.data.Record.map((ele)=>{
          id= id +1
          ele._id = id
          })
          console.log(response1.data.Record)
          setlastfi(response1.data.Record)
          localStorage.setItem('api',1)
          })
          .catch((err)=>{
            console.log("error in fecthing last five interactions",err.message)
          })
  }

  const getCustomerDetails =(acc)=>{
    localStorage.setItem('Customer Name',"N/A")
    localStorage.setItem('Customer Number',"N/A")
    localStorage.setItem('Product',"N/A")
    localStorage.setItem('Channel',"N/A")
    localStorage.setItem('Product link',"N/A")

    const data ={
      accountNumber:acc
    }
    axios.post(`/autodialerfileupload/channel/callingrecord`,data)
    .then((res)=>{
      console.log(res.data[0])
      setCurrentrecord(res.data.record[0])
      localStorage.setItem('Customer Name',res.data.record[0].name)
      localStorage.setItem('Customer Number',res.data.record[0].phone)
      localStorage.setItem('Product',res.data.record[0].product)
      localStorage.setItem('Channel',res.data.record[0].channel)
      localStorage.setItem('Product link',res.data.record[0].productlink)

      
      getLastFiveInteractions()
      getLastThreeOrders()
      // getLastThreeOrders()
      // getLastThreeOrders()
      // getLastFiveInteractions()
    })
    .catch((err)=>{
      console.log("error in fetching customer details",err.message)
      // alert(`Failed to fetch customer Details`)
    })
  }

  if(localStorage.getItem('callStatus') === 'AgentConnect'  && localStorage.getItem('api') === '0'){
                      console.log("AccountCode")
                      axios.get(`${AMI}/actions/accountcode?id=${localStorage.getItem('Interaction_id')}`)
                      .then((res)=>{
                          console.log("res.data.AccountCode",res)
                          localStorage.setItem('accountCode',res.data.AccountCode)
                          getCustomerDetails(res.data.AccountCode)
                      })
                      .catch((err)=>{
                        console.log(err)
                      })              
  }

  if(localStorage.getItem('callStatus') === 'AgentCalled'  && localStorage.getItem('api') === '0'){
    console.log("AccountCode")
    axios.get(`${AMI}/actions/accountcode?id=${localStorage.getItem('Interaction_id')}`)
    .then((res)=>{
        console.log("res.data.AccountCode",res)
        localStorage.setItem('accountCode',res.data.AccountCode)
        getCustomerDetails(res.data.AccountCode)
    })
    .catch((err)=>{
      console.log(err)
    })              
}

if(localStorage.getItem('callStatus') === 'AgentCompleted'  && localStorage.getItem('api') === '0'){
  console.log("AccountCode")
  axios.get(`${AMI}/actions/accountcode?id=${localStorage.getItem('Interaction_id')}`)
  .then((res)=>{
      console.log("res.data.AccountCode",res)
      localStorage.setItem('accountCode',res.data.AccountCode)
      getCustomerDetails(res.data.AccountCode)
  })
  .catch((err)=>{
    console.log(err)
  }) 
}     



  useEffect(() => {

    if(localStorage.getItem('Agent_Object_ID')){
      async function getInitialData() {
        try {
          await getAgentCallStatus(localStorage.getItem('Agent_Object_ID'));
        } catch (err) {
          console.log('err', err);
        }
    }
    getInitialData();
   
    }
    
    setLoadingDetails(false);
    localStorage.setItem('api',0)


  }, []);



  useEffect(() => {
    
   
    if(localStorage.getItem('Agent_Object_ID')){
      const agentSipID = localStorage.getItem('Agent_Object_ID')
      const interval = setInterval(async () => {
        const GET_CURRENT_STATUS_BY_AGENT_SIP_ID = `${AGENT_SERVICE}/agents/${localStorage.getItem('Agent_Object_ID')}`;
        const getCurrentStatus = await axios.get(GET_CURRENT_STATUS_BY_AGENT_SIP_ID);
        //console.log('getCurrentStatus', getCurrentStatus)
        getAgentCallStatus(agentSipID)
      }, 2000);
    }

   
   
  }, [])

  //window.location.reload()


  const [loadingDetails, setLoadingDetails] = useState(true);

  const { seconds, minutes, hours, start, reset } = useStopwatch({
    autoStart: false
  });

  const setBreakDuration = async () => {
    //console.log(userData);
    axios
      .post('/crm-route/breaks', {
        agent_Name: userData.username,
        agent_Id: userData.userId,
        Agentsip_id: userData.sip_id,
        break_duration: `Hr : ${hours} Min : ${minutes} Sec : ${seconds}`
      })
      .then(res => {
        console.log(res);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (timeEnabled) {
      start();
    } else {
      if (seconds || minutes) {
        setBreakDuration();
      }
      reset(0, false);
    }
  }, [timeEnabled]);

  const dail = async () => {
    console.log("i have dialed")
    await axios.get('https://dt.granalytics.in/ami/actions/orginatecall', {
      params: {
        sipAgentID: userData.sip_id,
        NumbertobeCalled: '1' + customer.phoneNumber.slice(2),
        restaurantId: customer.restaurantId
      }
    });
  };

  const getData = async () => {
    setFormDisabled(false)
   
    await axios
      .get(`${CAMPAIGN}/campaign/getdata`)
      .then(res => {
        console.log(res.data,"customer daaaaaaaaaaaa")
        setCustomer(res.data);
        res.data && setFormDisabled(false);
        
        setOpen(true);
        getCustomerlastThreeOrders()
      })
      .catch(err => {
        // setFormDisabled(true);
        console.log(err);
        setOpen(true);
      });
  };

  // console.log(customer)
  const getCustomerlastThreeOrders =()=>{
    var data = {"apiKey":"25c71cd65026ea2deef9d55c273c2b54","resId":customer.restaurantId,"phone": customer.phoneNumber};
    
    axios.post(`/crm-route/inrestoorders`,data)
      .then((res)=>{
        //console.log(res)
        var i=0
        res.data[0].items.map((ele)=>{
          i=i+1
          return ele.id = i
        })
        setOrders(res.data[0])
      })
      .catch((err)=>{
        console.log(err)
      })
  }
  //console.log(customer)

  const dialTimer = () => {
    if (customer) {
      setTimer(
        setInterval(() => {
          let remSecond;
          setSecondsLeft(prev => {
            remSecond = prev;
            return prev;
          });
          if (remSecond !== 0) {
            setSecondsLeft(remSecond - 1);
          } else {
            if(localStorage.getItem('accountCode')){

            }else{
              dail();
            setSecondsLeft(0);
            setTimer(prev => clearInterval(prev));
            }
            
          }
        }, 1000)
      );
    }
  };

  // //console.log(customer)
  useEffect(() => {
    if (customer !== null) {
      setSecondsLeft(15);
      dialTimer();
      const getLastFiveRecords = async () => {
        await axios
          .get(`/crm-route/lastthreeinteractions`, {
            params: {
              customerId: customer.customerId,
              interactionCount: 5
            }
          })
          .then(res => {
            console.log("lastthreeinteractions",res)
            setLastFiveRecords(res.data);
          })
          .catch(err => {
            console.log(err);
          });
      };
      getLastFiveRecords();

      console.log(customer)

      const getCustomerlastThreeOrders =(data)=>{
        //console.log(customer)
        if(Object.keys(customer) != 0){
          var data = {"apiKey":"25c71cd65026ea2deef9d55c273c2b54","resId":customer.restaurantId,"phone": customer.phoneNumber};
        
          axios.post(`/crm-route/inrestoorders`,data)
            .then((res)=>{
              //console.log(res)
              if(res.data.length > 0){
                var i=0
              res.data[0].items.map((ele)=>{
                i=i+1
                return ele.id = i
              })
              console.log(res.data,"order details")
              setOrders(res.data[0])
              }
              
            })
            .catch((err)=>{
              console.log(err)
            })
        }
       
      }
      getCustomerlastThreeOrders()
      
    } else {
      setSecondsLeft(0);
    }
    //getCustomerlastThreeOrders()
  }, [customer]);
  //console.log(orders,"orders")

  const handleClick = () => {
    setFormDisabled(false);
    getData();
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  //console.log(currentCall,"current caall")
  useEffect(()=>{

  },[])

  return (
    <>
    
    
      {!!secondsLeft && (
        <Button
          style={{
            float: 'right',
            marginRight: '4rem',
            color: 'white',
            marginBottom: '-2rem'
          }}
          variant="contained"
          color="secondary"
        >
          Call Connecting in {secondsLeft}s
        </Button>
      )}
      {timeEnabled && (
        <Button
          style={{
            float: 'right',
            marginRight: '4rem',
            color: 'white',
            marginBottom: '-2rem'
          }}
          variant="contained"
          color="secondary"
        >
          {`Hr : ${hours}  Min : ${minutes} Sec : ${seconds}`}
        </Button>
      )}
      <CustomBreadcrumbs />
     
      <div style={{ padding: '1rem 2rem 2rem' }}>
        <Grid container spacing={5}>
          {
            localStorage.getItem('Agent_Object_ID')? (null):(
              <Grid item lg={6} xs={12}>
              <LeadButtons customer={customer} />
            </Grid>
            )
          }
         
          {/* <Grid container item justify="flex-end" lg={3} xs={6}>
            {
              localStorage.getItem('Agent_Object_ID') ? (
                <Button
              variant="contained"
              color={timeEnabled ? 'secondary' : 'primary'}
              style={{ color: 'white' }}
              onClick={breakService}
              disabled={disable}
            >
              {timeEnabled ? 'Resume Work' : 'Take Break'}
            </Button>
              ):(
                <Button
              variant="contained"
              color={timeEnabled ? 'secondary' : 'primary'}
              style={{ color: 'white' }}
              onClick={handleBreakTimeOut}
            >
              {timeEnabled ? 'Resume Work' : 'Take Break'}
            </Button>
              )
            }
            
          </Grid> */}
          {
            localStorage.getItem('Agent_Object_ID') ? (null) :(
              <Grid container item justify="flex-end" lg={3} xs={6}>
              <Button
                variant="contained"
                color="primary"
                disabled={secondsLeft}
                onClick={handleClick}
              >
                Fetch New Customer
              </Button>
              
              <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                {formDisabled ? (
                  <Alert onClose={handleClose} severity="error">
                    Some error occur please try again !
                  </Alert>
                ) : (
                  <Alert onClose={handleClose} severity="success">
                    Fetch new customer successfully !
                  </Alert>
                )}
              </Snackbar>
            </Grid>
         
            )
          }
           </Grid>
        <Grid
          container
          direction="row"
          spacing={3}
          style={{ marginTop: '1rem' }}
        >
         
          <Grid container item lg={6} xs={12}>
            <Grid item xs={12}>
              <Card style={{ display: 'flex', justifyContent: 'center' }}>
                <CardHeader title={'Customer Details'} />
              </Card>
              {
                localStorage.getItem('accountCode') ? (
                  <Card style={{ padding: '1rem' }}>
                  <CustomerDetails1 customer={currentRecord} />
                </Card>
                ):(
                  <Card style={{ padding: '1rem' }}>
                  <CustomerDetails customer={customer} />
                </Card>
                )
              }
              {/* <Card style={{ padding: '1rem' }}>
                <CustomerDetails customer={customer} />
              </Card> */}
            </Grid>
            {/* <Grid item xs={12} style={{ marginTop: '1rem' }}>
              <RecentCustomerOrderDetails orders={orders}/>
            </Grid> */}
            {
              localStorage.getItem('accountCode') ? (
                <Grid item xs={12} style={{ marginTop: '1rem' }}>
              <RecentFiveRecords1
                records={lastfi !== null && lastfi}
              />
            </Grid>
              ):(
                <Grid item xs={12} style={{ marginTop: '1rem' }}>
              <RecentFiveRecords
                records={lastFiveRecords !== null && lastFiveRecords}
              />
            </Grid>
              )
            }
            
            {/* <Grid item xs={12} style={{ marginTop: '1rem' }}>
              <RecentFiveRecords
                records={lastFiveRecords !== null && lastFiveRecords}
              />
            </Grid> */}
          </Grid>
          <Grid item lg={6} xs={12}>
            <Card style={{ display: 'flex', justifyContent: 'center' }}>
              <CardHeader title={'Disposition Form'} />
            </Card>
            {
              localStorage.getItem('callStatus') === 'AgentConnect' || localStorage.getItem('callStatus') === 'AgentComplete' ? (<Card style={{ padding: '1rem' }}>
              <DispositionForm
                //  visibility={formDisabled}
                customer={currentRecord}
                setCurrentrecord={setCurrentrecord}
                getCustomerDetails={getCustomerDetails}
              />
            </Card>):null
            }
             {/* <DispositionForm
                //  visibility={formDisabled}
                customer={currentRecord}
                setCurrentrecord={setCurrentrecord}
                getCustomerDetails={getCustomerDetails}
              /> */}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Dashboard;
