import React, { useEffect, useState } from 'react';
import { Link, Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Badge,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Typography,
  InputBase,
  fade,
  Tooltip
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import Logo from 'src/modules/dashboard-360/components/Logo';
import { SearchIcon } from '@material-ui/data-grid';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';
import CallIcon from '@material-ui/icons/Call';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { setLoggedIn, setSearchDistributor } from 'src/redux/action';
import { connect } from 'react-redux';
import Axios from 'axios';
import { LOGOUT_URL } from 'src/modules/auth/utils/endpoints';
import NavBar from './Navbar';
import navBarRoutes from 'src/utils/navBarRoutes';
import navBarRoutesAdmin from 'src/utils/navBarRoutesAdmin';
import { useSelector } from 'react-redux';
import axios from 'axios';
import {AGENT_SERVICE,AMI,LOGOUT} from 'src/modules/dashboard-360/utils/endpoints'
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
// import MenuIcon from '@material-ui/icons/Menu';
// import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
const useStyles = makeStyles(theme => ({
  root: {
    // '.MuiFormLabel-root': {
    //   color: '#f6fbff'
    // },
    backgroundColor: '#f6fbff',
    color:'#3f51b5',
    boxShadow:'0 8px 6px -6px #999'
  },
  avatar: {
    width: 60,
    height: 60
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(4),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontWeight: 500,
    marginRight: 15,
    fontSize: '0.96rem',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  inputRoot: {
    color: 'white'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '18ch',
      '&:focus': {
        width: '30ch'
      }
    }
  }
}));

const TopBar = ({
  className,
  logout,
  searchDist,
  permissions,
  userRole,
  ...rest
}) => {
  // const userData = useSelector(state => state.userData);
  // const [createAccess, setCreateAccess] = useState(-1);
  // const [viewAccess, setViewAccess] = useState(-1);
  // const [assignAccess, setAssignAccess] = useState(-1);
  // const [reportsAccess, setReportsAccess] = useState(-1);
  // const [editAccess, setEditAccess] = useState(-1);
  // const [role, setRole] = useState(-1);
  const userData = useSelector(state => state.userData);
  const [restaurantName, setRestaurantName] = useState(null);

  const classes = useStyles();
  const [notifications] = useState([]);
  const [searchText, setSearchText] = useState('');
  const history = useHistory();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [timeEnabled, setTimeEnabled] = useState(false);
  const [disable,setDisable]= useState(false)
  const [event,setEvent]= useState("")

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleBreakTimeOut = () => {
    //console.log("i am called")
    setTimeEnabled(!timeEnabled);
  };

  setInterval(function(){ 
    
    setEvent(localStorage.getItem('callStatus'))
  }, 3000);

  function breakService() {
    //console.log("called from dispos")
    const AgentSIPID = localStorage.getItem('AgentSIPID1')
    if (localStorage.getItem('Break_Status') === 'OUT') {
      var axios = require('axios');
      var config = {
        method: 'get',
        url: `${AMI}/actions/break?Queue=${localStorage.getItem('Queue')}&Interface=${AgentSIPID}&Reason=BREAKIN&Break=true`,
        headers: {}
      };

      axios(config)
        .then(function (response) {
          console.log((response.data));
          handleBreakTimeOut()
        })
        .catch(function (error) {
          console.log(error.message);
        });
    } else {
      var axios = require('axios');
      var config = {
        method: 'get',
        url: `${AMI}/actions/break?Queue=${localStorage.getItem('Queue')}&Interface=${AgentSIPID}&Reason=BREAKOUT&Break=false`,
        headers: {}
      };

      axios(config)
        .then(function (response) {
          console.log((response.data));
          handleBreakTimeOut()
        })
        .catch(function (error) {
          console.log(error);
        });
    }

  }

  useEffect(()=>{
    if(localStorage.getItem('callStatus') === 'AgentConnect' || localStorage.getItem('callStatus') === 'AgentComplete'){
      setDisable(true)
    }else{
      
      setDisable(false)
      // setFormDisabled(true)
      
    }
  },[localStorage.getItem('callStatus')])


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const menuId = 'primary-search-account-menu';
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  // useEffect(() => {
  //   const apiUrl = config.APIS_URL + '/access/email/' + userData.email;
  //   fetch(apiUrl)
  //     .then(res => res.json())
  //     .then(repos => {
  //       setRole(repos.role.role);
  //       setCreateAccess(
  //         parseInt(
  //           (
  //             repos.data.filter(
  //               access => access.functionalityId === '1'
  //             )[0] || { accessLevelId: -1 }
  //           ).accessLevelId
  //         )
  //       );
  //       setViewAccess(
  //         parseInt(
  //           (
  //             repos.data.filter(
  //               access => access.functionalityId === '2'
  //             )[0] || { accessLevelId: -1 }
  //           ).accessLevelId
  //         )
  //       );
  //       setEditAccess(
  //         parseInt(
  //           (
  //             repos.data.filter(
  //               access => access.functionalityId === '3'
  //             )[0] || { accessLevelId: -1 }
  //           ).accessLevelId
  //         )
  //       );
  //       setAssignAccess(
  //         parseInt(
  //           (
  //             repos.data.filter(
  //               access => access.functionalityId === '4'
  //             )[0] || { accessLevelId: -1 }
  //           ).accessLevelId
  //         )
  //       );
  //       setReportsAccess(
  //         parseInt(
  //           (
  //             repos.data.filter(
  //               access => access.functionalityId === '5'
  //             )[0] || { accessLevelId: -1 }
  //           ).accessLevelId
  //         )
  //       );
  //     });
  // }, []);
  const updateSearchText = evt => {
    setSearchText(evt.target.value);
  };
  const distributorID = evt => {
    searchDist(searchText);
  };

  async function logoutUser() {
 
    if(localStorage.getItem('Agent_Object_ID')){
      if (localStorage.getItem('callStatus') === 'AgentDisposed' || localStorage.getItem('callStatus') === 'LoggedIn' || localStorage.getItem('callStatus') === 'BREAKOUT' || localStorage.getItem('callStatus') === 'BREAKIN' || localStorage.getItem('callStatus') === 'AgentRingNoAnswer' || localStorage.getItem('callStatus') === 'AgentCalled') {
        var axios = require('axios');
        var data = JSON.stringify({ "Event": "LoggedOut" });
  
        var config = {
          method: 'put',
          url: `${AGENT_SERVICE}/agents/${localStorage.getItem('Agent_Object_ID')}`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
  
        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data), "status changed");
          })
          .catch(function (error) {
            console.log(error);
          });
  
        var axios = require('axios');
        var data = '';
  console.log("remove queue",localStorage.getItem('AgentSIPID1'))
        var config = {
          method: 'get',
          url: `${AMI}/actions/rmq?Queue=${localStorage.getItem('Queue')}&Interface=${localStorage.getItem('AgentSIPID1')}`,
          headers: {},
          data: data
        };
  console.log("config",config)
        axios(config)
          .then(function (response) {
            console.log(response.data, "removed from queue");
          })
          .catch(function (error) {
            console.log(error);
          });
  
        const AgentSIPID = localStorage.getItem('AgentSIPID1')
        var axios = require('axios');
        var config = {
          method: 'get',
          url: `${AMI}/actions/break?Queue=${localStorage.getItem('Queue')}&Interface=${AgentSIPID}&Reason=BREAK_IN&Break=true`,
          headers: {}
        };
  
        axios(config)
          .then(function (response) {
            //console.log((response.data));
            logout()
            localStorage.clear()
            // window.location.reload()
  
          })
          .catch(function (error) {
            console.log(error);
          });
    }
     Axios.get(LOGOUT_URL);
      logout();
      //console.log("i am called 1")
      localStorage.clear()
      //window.location.reload()
     
    
    }else {
      try {
        await Axios.get(LOGOUT_URL);
        //console.log("i am called")
        logout();
        localStorage.clear()
      window.location.reload()
      } catch (err) {
        console.log(err);
      }
    }
  
  }

  useEffect(() => {
    if (userData.role === 'DTL2' || userData.role === 'DTRestaurantManager') {
      axios
        .get('/api/crm-route/restaurant', {
          params: {
            id: userData.restaurants[0]
          }
        })
        .then(res => {
          setRestaurantName(res.data.restaurantName);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, []);
  
  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          {/* <Logo /> */}
        </RouterLink>
        <Logo />
        {
              localStorage.getItem('AgentType') ==='L1'? (
                <Button
              variant="contained"
              color={timeEnabled ? 'secondary' : 'primary'}
              style={{ color: 'white' }}
              onClick={breakService}
              disabled={disable}
            >
              {timeEnabled ? 'Resume Work' : 'Take Break'}
            </Button>
              ):(
                <></>
              )
            }
        
       
        <Box flexGrow={1} />
       
        <Hidden mdDown>
          {navBarRoutes(permissions, userRole)
            .filter(role => role.isVisible)
            .map(route => (
              <Typography
                className={classes.title}
                variant="h5"
                noWrap
                key={Math.random()}
              >
                <Link to={route.link} className="root">
                  {route.title}
                </Link>
              </Typography>
            ))}
         {userData.role === 'admin' && (

          <>
          {/* <p onClick={handleClick}>Reports</p> */}
<Typography
                // className={classes.title}
                variant="h5"
                // noWrap
                // key={Math.random()}
              >
<Link  className="root" onClick={handleClick}>
                 Reports 
                </Link>
                </Typography>  
<Menu
  id="simple-menu"
  anchorEl={anchorEl}
  keepMounted
  open={Boolean(anchorEl)}
  onClose={handleMenuClose}
>
{navBarRoutesAdmin(permissions, userRole)
            .filter(role => role.isVisible)
            .map(route => (
              <Typography
                className={classes.title}
                variant="h5"
                noWrap
                key={Math.random()}
              >
                <Link to={route.link} onClick={handleMenuClose} className="root">
                  {route.title}
                </Link>
              </Typography>
            ))}
  
</Menu>
</>)}
          
           
          {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit" onClick={() => history.push('/user')}>
            <AccountBoxRoundedIcon />
          </IconButton> */}
          {userData.role === 'DTL1' && localStorage.getItem('Agent_Object_ID') ? (
            <Box
            alignItems="center"
            display="flex"
            className={`${classes.timerComp1} ${classes.callWrapper1} ${classes.callInbound1}`}>
              
            {
                 event === 'AgentCalled' ? (<div>
                   <CallIcon />
             &nbsp;
                   <Typography display="inline">
                     {/* {localStorage.getItem('callerNumber')} */}
           Call Ringing
         </Typography>
                 </div>) : null
               }
               
               {
                 event === 'AgentComplete' ? (<div>
                   <CallIcon />
             &nbsp;
                   <Typography display="inline">
                     {/* {localStorage.getItem('callerNumber')} */}
                     {localStorage.getItem('CallerNumber')}- Call Disconnected
         </Typography>
                 </div>) : null
               }
                {
                 event === 'AgentConnect' && localStorage.getItem('customerName') === 'N/A' ? (<div>
                   <CallIcon />
             &nbsp;
                   <Typography display="inline">
                     {/* {localStorage.getItem('callerNumber')} */}
                     {localStorage.getItem('CallerNumber')}-Call in Progress / Error in fetching Customer Details
         </Typography>
                 </div>) : null
               }
                {
                 event === 'AgentConnect' && localStorage.getItem('customerName') !== 'N/A' ? (<div>
                   <CallIcon />
             &nbsp;
                   <Typography display="inline">
                     {/* {localStorage.getItem('callerNumber')} */}
                     {localStorage.getItem('CallerNumber')}-Call in Progress
         </Typography>
                 </div>) : null
               }
               {
                 event === 'AgentDisposed' || event === 'LoggedIn' || event === 'BREAKOUT' ? (<div>
                   <CallIcon />
             &nbsp;
                   <Typography display="inline">
                     {/* {localStorage.getItem('callerNumber')} */}
          Free for next call
         </Typography>
                 </div>) : null
               }
               {
                 event === 'BREAKIN' ? (<div>
                   <CallIcon />
             &nbsp;
                   <Typography display="inline">
                     {/* {localStorage.getItem('callerNumber')} */}
                   You are in Break
         </Typography>
                 </div>) : null
               }
               {
                 event === 'AgentRingNoAnswer' ? (<div>
                   <CallIcon />
             &nbsp;
                   <Typography display="inline">
                     {/* {localStorage.getItem('callerNumber')} */}
          Call Not Answered
         </Typography>
                 </div>) : null
               }
            </Box>
          ):(<></>)}
          {userData.role === 'DTL1' && (
            <Typography variant="h5">&nbsp;&nbsp;&nbsp;&nbsp;{`User-L1`}&nbsp;&nbsp;</Typography>
          )}
           {userData.role === 'DTL1' && (
            <Typography variant="h5">&nbsp;&nbsp;&nbsp;&nbsp;{localStorage.getItem('AgentName')}</Typography>
          )}
          {restaurantName !== null && (
            <Typography variant="h5">{restaurantName}</Typography>
          )}
          {userData.role === 'DTAreaManager' && (
            <Typography variant="h5">{userData.username}</Typography>
          )}
            {/* <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
              </IconButton> */}
            {/* <img
      alt="Logo"
      src="/static/gr3.png"
      // {...props}
      style={{ height: '35px',width:'100px' }}
    /> */}
    
          <Tooltip title="Logout">
            <IconButton color="inherit" onClick={() => logoutUser()}>
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={() => setMobileNavOpen(true)}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      
      </Toolbar>

      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        permissions={permissions}
        userRole={userRole}
        onLogout={logoutUser}
      />
      {/* {renderMenu} */}

    </AppBar>
  );
};
const mapStateToProps = state => ({
  searchtextdist: state.searchDistributor,
  permissions: state.permissions,
  userRole: state.userData?.role
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(setLoggedIn(false)),
  searchDist: val => dispatch(setSearchDistributor(val))
});

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
  logout: PropTypes.func,
  searchDist: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
