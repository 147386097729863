import AdminDashboard from '../views/admin/admin-dashboard';
import manageagents from '../../../components/Auto-report/manageagents';

export default [
  // {
  //   path: '/report',
  //   exact: false,
  //   crumb: 'Report',
  //   key: 'report',
  //   component: Report,
  //   selector: 'admin.canAccessAdminReports'
  // }
    {
    path: '/manageAgents',
    // routes: adminRoutes,
    exact: false,
    key: 'admin',
    component: manageagents,
    crumb: 'Admin',
    selector: 'admin.canAccessAdminReports'
},
];
