import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { DataGrid } from '@material-ui/data-grid';
import {
  Grid,
  Card,
  CardContent,
  Tooltip,
  IconButton,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core';
import axios from 'axios'
import moment from 'moment';
import Date from './daterange1'
import Download from '../DownloadReport'
import { CAMPAIGN } from 'src/modules/dashboard-360/utils/endpoints'


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

// function a11yProps(index) {
//   return {
//     id: `full-width-tab-${index}`,
//     'aria-controls': `full-width-tabpanel-${index}`
//   };
// }

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    margin: '1rem 2rem'
  }
}));


const Inbound = () => {
  const classes = useStyles();
  // const [uploadFileStatus, setUploadFileStatus] = useState(null);
  // const theme = useTheme();
  // const [setValue] = React.useState(0);
  const [profiles, setProfiles] = useState([])
  // const [startDate, setStartDate] = useState("")
  // const [enddate, setEnddate] = useState("")
  const [option, setOption] = useState("ALL")

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);

  // };
  //   const handleStatus = (e) => {
  //     setOption(e.target.value)

  //   }
  const getProfiles = async () => {
    // var dateFormat = 'DD-MM-YYYY';
    // let startDate = localStorage.getItem('startDate')
    // var testDateUtc1 = moment.utc(startDate);
    // var localDate1 = testDateUtc1.local()
    // startDate = localDate1.format(dateFormat);

    let startDate = moment(localStorage.getItem('startDate')).format().slice(0, 10);
    let EndDate = moment(localStorage.getItem('EndDate')).format().slice(0, 10);
    let option1 = localStorage.getItem('option')

    const data = {
      "startdate": startDate,
      "enddate": EndDate,
      "status": option1
    }

    console.log(data)

    console.log(startDate, EndDate)

    try {
      await axios.post(`${CAMPAIGN}/channel/getFilterExcel`, data)
        .then((response) => {
          console.log("res", response)
          var i = 0;
          if (response.data.Record.length > 0) {


            // console.log(response.data.Record)

            response.data.Record.map((ele) => {
              i = i + 1;
              var dateFormat = 'DD-MM-YYYY HH:mm:ss';
              var endUtc = moment.utc(ele.enddate);
              var startUtc = moment.utc(ele.startdate);
              var updatedAtUtc = moment.utc(ele.updatedAt);
              var createdAtUtc = moment.utc(ele.createdAt);
              var localeDate = endUtc.local();
              var localsDate = startUtc.local();
              var localUdDate = updatedAtUtc.local();
              var localcrDate = createdAtUtc.local();
              ele.enddate = localeDate.format(dateFormat);
              ele.startdate = localsDate.format(dateFormat);
              ele.updatedAt = localUdDate.format(dateFormat);
              ele.createdAt = localcrDate.format(dateFormat);
              ele.cdr = ele.cdr.length;
              return ele.id = i;
            })

            setProfiles(response.data.Record)
          }
          else {
            setProfiles([])
          }

        })

    } catch (err) {
      console.log(err)
    }

  }



  // const handleChangeIndex = index => {
  //   setValue(index);
  // };

  const profilesColumns = [
    {
      headerName: 'Campaign Name',
      field: 'ivrCampaignName',
      flex: 0.5

    },
    {
      headerName: 'Ref.Id',
      field: 'refid',
      flex: 0.5

    },
    {
      headerName: 'Restaurant ID',
      field: 'restaurantID',
      flex: 0.5
    },
    {
      headerName: 'Restaurant Name',
      field: 'restaurantName',
      flex: 0.5
    },
    {
      headerName: 'Customer Phonenumber',
      field: 'customerPhonenumber',
      flex: 0.5
    },
    {
      headerName: 'Rating',
      field: 'rating',
      flex: 0.5
    },
    {
      headerName: 'Account Number',
      field: 'accountNumber',
      flex: 0.5
    },
    {
      headerName: 'Disposition',
      field: 'Disposition',
      flex: 0.5
    },
    {
      headerName: 'Comments',
      field: 'comments',
      flex: 0.5
    },
    {
      headerName: 'Retries',
      field: 'retries',
      flex: 0.5
    },

    {
      headerName: 'Status',
      field: 'status',

      renderCell: rowData => (
        <>
          {
            rowData.row.status === '0' && (<div>
              <Tooltip title="Not Dialed">
                <IconButton

                ><Typography>Not Dialed</Typography>
                </IconButton>
              </Tooltip>
            </div>)
          }
          {
            rowData.row.status === '1' && (<div>
              <Tooltip title="Dialed">
                <IconButton

                ><Typography>Dialed</Typography>
                </IconButton>
              </Tooltip>
            </div>)
          }
        </>
      ),
      flex: 0.5
    },
    {
      headerName: 'Created At',
      field: 'createdAt',
      flex: 1
    },
    {
      headerName: 'Updated At',
      field: 'updatedAt',
      flex: 1
    },
    {
      headerName: 'CDR record Count',
      field: 'cdr',
      flex: 1
    },
    {
      headerName: 'Job Status',
      field: 'jobStatus',

      renderCell: rowData => (
        <>
          {
            rowData.row.jobStatus === '0' && (<div>
              <Tooltip title="Job not Completed">
                <IconButton

                ><Typography>Job not Completed</Typography>
                </IconButton>
              </Tooltip>
            </div>)
          }
          {
            rowData.row.jobStatus === '1' && (<div>
              <Tooltip title="Job Completed">
                <IconButton

                ><Typography>Job Completed</Typography>
                </IconButton>
              </Tooltip>
            </div>)
          }
        </>
      ),
      flex: 0.5
    }


  ];

  const handleStatus = (e) => {
    setOption(e.target.value)

  }
  // getProfiles()

  const showProfile = (data) => {
    // console.log(data)
  }

  const handlefetch = (e) => {
    localStorage.setItem('option', option)
  }

  useEffect(() => {
    localStorage.setItem('callStatus', 'AgentDisposed')
    getProfiles()

    const interval = setInterval(async () => {
      if(window.location.href.includes('campaignreport')){
        getProfiles()
    }
      

    }, 1000);

    console.log(interval)

  }, [])


  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} xs={0}></Grid>
        <Grid item lg={4} md={12} xs={0}></Grid>
        <Grid item lg={6} md={12} xs={0}>
          <h1 style={{ textAlign: 'center' }}>Job status Dashboard</h1>
        </Grid>
        <Grid item lg={2} md={12} xs={0}></Grid>
      </Grid>
      <br /><br />
      <Grid>
        <Card>
          <CardContent>
            <Grid container spacing={3}>

              <Grid item lg={2} md={2} xs={6}>
                <Date value="Start Date" />
              </Grid>
              <Grid item lg={2} md={2} xs={6}>
                <Date value="End Date" />
              </Grid>
              <Grid item lg={2} md={2} xs={6}>
                <FormControl variant="outlined" className={classes.formControl} required="true" fullWidth={true}  >
                  <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={option}
                    onChange={handleStatus}
                    label="Status"
                  // required="true"
                  >
                    <MenuItem value="ALL">All</MenuItem>
                    <MenuItem value="1">Dialed</MenuItem>
                    <MenuItem value="0">Not Dialed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={2} md={2} xs={6}>
                <Button variant="outlined" color="primary" onClick={handlefetch}>Fetch Data</Button>
              </Grid>
              {
                profiles.length > 0 ? (
                  <Grid item lg={2} md={2} xs={6}>
                    <button type="button" class="btn btn-light"><Download DownloadData={profiles} /></button>
                  </Grid>
                ) : (null)
              }

            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {
        profiles.length > 0 ? (

          <Grid>
            <Card>
              <CardContent>
                <div style={{ height: 500, width: '100%' }}>
                  <DataGrid rows={profiles} columns={profilesColumns} pageSize={20}
                    // rowsPerPageOptions={[10, 20, 50]}
                    onRowClick={showProfile}
                    pagination />
                </div>

              </CardContent>
            </Card>

          </Grid>
        ) : (null)}
    </>
  );
};

export default Inbound;
