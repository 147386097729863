import React, { useEffect } from 'react'

import {
    Grid,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions
} from '@material-ui/core';

// import axios from 'axios'



const Popup = (props) => {
    const { handleClose, show, modaldata } = props









    useEffect(() => {
        // console.log("props",props)
    }, [])


    return (<div>
        <Dialog
            open={show}
            onClose={() => handleClose()}
            style={{ padding: 2 }}
            fullWidth={true}
        >
            <DialogTitle>Job details</DialogTitle>
            < DialogContent>
                <Grid container spacing={3} direction="row">
                    <Grid item xs={12} sm={6}>

                        <p><b>Campaign Name: </b>{modaldata[0].ivrCampaignName}</p>
                        <p><b>Agent Name: </b>{modaldata[0].agentName}</p>
                        <p><b>Agent SIP ID: </b>{modaldata[0].sip_id}</p>
                        <p><b>Customer Name: </b>{modaldata[0].CustomerName}</p>

                        <p><b>Customer Number: </b>{modaldata[0].mobileNumber}</p>

                        <p><b>Call Start Time: </b>{modaldata[0].Call_StartTime}</p>
                        <p><b>Call Answer Time: </b>{modaldata[0].Call_AnswerTime}</p>
                        <p><b>Call End Time: </b>{modaldata[0].Call_EndTime}</p>
                        <p><b>Call Duration: </b>{modaldata[0].Call_Duration}</p>
                        <p><b>Attempts: </b>{modaldata[0].Attempts}</p>
                        <p><b>Disposition: </b>{modaldata[0].Disposition}</p>
                        <br />
                        <p><b><h3>Interaction Captured</h3> </b></p>
                       
                        <p><b>Call Status: </b>{modaldata[0].Call_Status}</p>
                        <p><b>Main Disposition: </b>{modaldata[0].mainDisposition}</p>
                        <p><b>Reason: </b>{modaldata[0].Reason}</p>
                        <p><b>Request Callback </b>{modaldata[0].Request_Callback}</p>
                        <p><b>Escalated: </b>{modaldata[0].Escalated}</p>
                        <p><b>Reason for Escalation: </b>{modaldata[0].Reason_for_Escalation}</p>
                        <p><b>Created At: </b>{modaldata[0].createdAt}</p>

                        {/* <h1></h1> */}
                    </Grid>
                    <br></br>
                </Grid>


            </DialogContent>
            <DialogActions>
                <p><Button variant="contained" onClick={handleClose}>Close</Button></p>
            </DialogActions>
        </Dialog>
    </div >)
}

export default Popup