import home from '../views/home';

export default [
  {
    path: '/home',
    key: 'home',
    component: home
  }
  // {
  //   path: '/new',
  //   key: 'createSurvey',
  //   component: CreateSurvey,
  //   crumb: 'Create Survey',
  //   selector: 'survey.canAccessSurveyAdminPrivileges'
  // },
];
