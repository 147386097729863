import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
function createData(key, value) {
  return { key, value };
}


const CustomerDetails = ({ customer }) => {
  const callAttempt = () => {
    if (!customer.hasOwnProperty('attemptCallBack')) return;
    if (customer.attemptCallBack === 0) return;
    if (customer.attemptCallBack === 1) return '1st Attempt';
    if (customer.attemptCallBack === 2) return '2nd Attempt';
    return '3rd Attempt';
  };
  let rows = [];
  console.log("customer",customer)
  if (customer !== null &&customer["interactions"] !== undefined) {

//     var obj = { key: undefined };
// obj["key"] !== undefined
if(customer.interactions[0].hasOwnProperty('CRMDISPOSITION')){
    rows = [
      createData('IEC', customer.iEC),
      createData('Company Name', customer.companyName),
     
      createData('City', customer.city),
      createData('State', customer.state),
      createData('Email', customer.email),
      createData('LTM Size', customer.lTMSize),
      createData('Product', customer.product),
      createData('Product Category', customer.productCategory),
      // createData('Call Attempts', callAttempt()),
      // createData('Outlet', customer.outlet),
      // createData('Feedback Time', moment(customer.updatedAt).format('MMMM Do YYYY, h:mm:ss a')),
      // createData('Region Manager Name', customer.RegionManager),
      // createData('Area Manager Name', customer.amname),
      // createData('Reason for escalation',customer.interactions[0].CRMDISPOSITION.Reason_for_Escalation)
    ];
  }
  else{
    rows = [
      // createData('Call Attempts', callAttempt()),
      // createData('Outlet', customer.outlet),
      // createData('Feedback Time', moment(customer.updatedAt).format('MMMM Do YYYY, h:mm:ss a')),
      // createData('Region Manager Name', customer.RegionManager),
      // createData('Area Manager Name', customer.amname),
      createData('IEC', customer.iEC),
      createData('Company Name', customer.companyName),    
      createData('City', customer.city),
      createData('State', customer.state),
      createData('Email', customer.email),
      createData('LTM Size', customer.lTMSize),
      createData('Product', customer.product),
      createData('Product Category', customer.productCategory),
    ];
  }
  }
  // else if(customer.interactions!== null){
    // rows = [
    //   // createData('Guest Name', customer.guestName),
    //   // createData('Experience Rating', customer.overallExperience),
    //   createData('Call Attempts', callAttempt()),
    //   // createData('Overall Rating', customer.overallRating),
    //   createData('Outlet', customer.outlet),
    //   // createData('Feedback Date', customer.feedbackRegisteredDate),
    //   // createData('Feedback Time', customer.feedbackRegisteredTime),
    //   // createData('Comments', customer.comments),
    //   createData('Region Manager Name', customer.RegionManager),
    //   createData('Area Manager Name', customer.amname),
    //   createData('Reason for escalation',customer.interactions[0].CRMDISPOSITION.agentRemarks)
    // ];
  // }
  else{
    
    // rows = [
    //   // createData('Guest Name', customer.guestName),
    //   // createData('Experience Rating', customer.overallExperience),
    //   createData('Call Attempts', callAttempt()),
    //   // createData('Overall Rating', customer.overallRating),
    //   createData('Outlet', customer.outlet),
    //   // createData('Feedback Date', customer.feedbackRegisteredDate),
    //   // createData('Feedback Time', customer.feedbackRegisteredTime),
    //   // createData('Comments', customer.comments),
    //   createData('Region Manager Name', customer.RegionManager),
    //   createData('Area Manager Name', customer.amname),
    //   // createData('Reason for escalation',customer.interactions[0].CRMDISPOSITION.agentRemarks)
    // ];
  }
  return (
    <>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="customer table">
          <TableBody>
            {rows.length !== 0 &&
              rows.map(row => (
                <TableRow key={row.key}>
                  <TableCell component="th" scope="row">
                    {row.key}
                  </TableCell>
                  <TableCell align="right">{row.value}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CustomerDetails;
