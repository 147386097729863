import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const RecentFiveRecords = ({ records }) => {
    console.log(records ,"last records")
  const columns = [
    {
        field: '_id',
        headerName: 'ID',
        flex: 0.5,
        renderCell: rowData => rowData.row._id
      },
      {
        field: 'AGENTNAME',
        headerName: 'Agent Name',
        flex: 2,
        renderCell: rowData => rowData.row.AGENTNAME
      },
      {
        field: 'Location',
        headerName: 'Location',
        flex: 2,
        renderCell: rowData => rowData.row.Location
      },
      {
        field: 'call_status',
        headerName: 'call_status',
        flex: 2,
        renderCell: rowData => rowData.row.call_status
      },
      {
        field: 'main_Disposition',
        headerName: 'Main Disposition',
        flex: 2,
        renderCell: rowData => rowData.row.main_Disposition
      },
     
      {
        field: 'Reason',
        headerName: 'Reason',
        flex: 2,
        renderCell: rowData => rowData.row.Reason
      }
    

    

  ];

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="body1">Last 3 Customer Interactions</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {
          records.length > 0 ? (
            <div style={{ height: 380, width: '100%' }}>
            <DataGrid
              columns={columns}
              rows={
                records.length
                  ? records.map(record => ({
                      ...record,
                      id: record._id
                    }))
                  : []
              }
              pageSize={5}
              pagination
              autoHeight
            />
          </div>
          ) :(null)
        }
      </AccordionDetails>
    </Accordion>
  );
};

export default RecentFiveRecords;