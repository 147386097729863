import React, { useEffect, useState } from 'react'
import {
    Grid,
    Button,
    Dialog,
    DialogContent,
    DialogTitle
} from '@material-ui/core';

const Popup = (props) => {
    const { show, handleClose, record,login,logout,breakIN,breakOut } = props
    
    const [record1,setrecord1] = useState({})
  
    useEffect(()=>{
       setrecord1(record)

    },[])

    console.log(record,login)
    return (<div>
        <Dialog
            open={show}
            onClose={() => handleClose()}
            style={{ padding: 2 }}
        >
            <DialogTitle>Log details</DialogTitle>
            < DialogContent>
                <Grid container spacing={3} direction="row">
                    <Grid item xs={6} sm={6} lg={6}>
                        {
                            login.length > 0 && <>
                                <h3>Logins</h3><br/>
                                {
                                    login.map((ele)=>{
                                        return <li><b>{ele}</b></li>
                                    })
                                }
                            </>
                        }
                       
                    </Grid>
                    <Grid item xs={6} sm={6} lg={6}>
                        {
                            logout.length > 0 && <>
                                <h3>LogOuts</h3><br/>
                                {
                                    logout.map((ele)=>{
                                        return <li><b>{ele}</b></li>
                                    })
                                }
                            </>
                        }
                       
                    </Grid>
                    <Grid item xs={6} sm={6} lg={6}>
                        {
                            breakIN.length > 0 && <>
                                <h3>Break IN</h3><br/>
                                {
                                    breakIN.map((ele)=>{
                                        return <li><b>{ele}</b></li>
                                    })
                                }
                            </>
                        }
                       
                    </Grid>
                    <Grid item xs={6} sm={6} lg={6}>
                        {
                            breakOut.length > 0 && <>
                                <h3>Break Out</h3><br/>
                                {
                                    breakOut.map((ele)=>{
                                        return <li><b>{ele}</b></li>
                                    })
                                }
                            </>
                        }
                       
                    </Grid>
                    <p><Button variant="contained" onClick={() => { handleClose() }}>Close</Button></p>
                </Grid>
            </DialogContent>
        </Dialog>
    </div>)
}

export default Popup