import React,{useEffect, useState} from 'react'
import Chart from "react-google-charts";
import { DataGrid} from "@material-ui/data-grid";
import { Button } from '@material-ui/core';
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
//

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#cfd8dc",
  },
}));

//
export default function MyChart() {
  const [dateFileter, setDateFileter] = useState(false); 
  const [startDate, setStartDate] = useState(""); 
  const [endDate, setEndDate] = useState(""); 
  const [startDateInteractions, setStartDateInteractions] = useState(""); 
  const [endDateInteractions, setEndDateInteractions] = useState(""); 
  //
  const [startDateInteractionsDownload, setStartDateInteractionsDownload] = useState(""); 
  const [endDateInteractionsDownload, setEndDateInteractionsDownload] = useState(""); 
  const [ItemsDateInteractionsDownload, setItemsDateInteractionsDownload] = useState("");
  const [isLoadedBetweenDatesDownload, setIsLoadedBetweenDatesDownload] = useState(false);
  const [isLoadedBetweenDatesInteractionsLoadingDownload, setIsLoadedBetweenDatesInteractionsLoadingDownload] = useState(false);
  //
  const classes = useStyles();
  const [valuetabs, setValueTabs] = React.useState(0);

  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };
  //
  function findBetweenDateInteractionsDownload(){
    setIsLoadedBetweenDatesInteractionsLoadingDownload(true)
    if(startDateInteractionsDownload === "" || endDateInteractionsDownload === ""){
      alert("enter date")
    setIsLoadedBetweenDatesInteractionsLoadingDownload(false)

    }else{

      fetch("https://duroflex.granalytics.in/zohoUpdates/zohoUpdates/createCountReportBetweenDatesInteractsPushed",
      {method:"POST", headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(
     {
    "fromDateTimeStamp": startDateInteractionsDownload,
    "tillDateTimeStamp": endDateInteractionsDownload
})
  })
      .then(res => res.json())
      .then(
        (result) => {
          setItemsDateInteractionsDownload(result.data);
          setIsLoadedBetweenDatesDownload(true);
          setIsLoadedBetweenDatesInteractionsLoadingDownload(false)

        },
        (error) => {
          setError(error);
          setIsLoadedBetweenDatesDownload(false);
          setIsLoadedBetweenDatesInteractionsLoadingDownload(false)
          alert("api error contact admin")
        }
      )
    }
    
  }
  //
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
 const [items, setItems] = useState([]);
 //
 const [itemsBetweenDates, setItemsBetweenDates] = useState([]);
 const [itemsBetweenDatesInteractions, setItemsBetweenDatesInteractions] = useState([]);

 const [errorBetweenDates, setErrorBetweenDates] = useState(null);
 const [isLoadedBetweenDates, setIsLoadedBetweenDates] = useState(false);

 const [isLoadedBetweenDatesInteractions, setIsLoadedBetweenDatesInteractions] = useState(false);
 const [isLoadedBetweenDatesInteractionsLoading, setIsLoadedBetweenDatesInteractionsLoading] = useState(false);


  useEffect(() => {
    fetch("https://duroflex.granalytics.in/zohoUpdates/zohoUpdates/getCount")
      .then(res => res.json())
      .then(
        (result) => {
          setItems(result);
        },
        (error) => {
          setIsLoaded(true);
          setErrorBetweenDates(error);
        }
      )
      //
     
  }, [])

  function findBetweenDate(){
    if(startDate === "" || endDate === ""){
      alert("enter date")
    }else{
      setIsLoadedBetweenDates(false)
    fetch("https://duroflex.granalytics.in/zohoUpdates/zohoUpdates/getCountRange",{method:"POST", headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      "fromDateTimeStamp" : startDate,
      "tillDateTimeStamp": endDate
   })})
      .then(res => res.json())
      .then(
        (result) => {
          setItemsBetweenDates(result);
          setIsLoadedBetweenDates(true);
        },
        (error) => {
          setError(error);
          alert('err')
        }
      )
    }
    
  }

  function findBetweenDateInteractions(){
    setIsLoadedBetweenDatesInteractionsLoading(true)
    if(startDateInteractions === "" || endDateInteractions === ""){
      alert("enter date")
    }else{

      fetch("https://duroflex.granalytics.in/zohoUpdates/zohoUpdates/createCountReportBetweenDatesOnCalledDate",
      {method:"POST", headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(
     {
    "fromDateTimeStamp": startDateInteractions,
    "tillDateTimeStamp": endDateInteractions
})
  })
      .then(res => res.json())
      .then(
        (result) => {
          setItemsBetweenDatesInteractions(result.data);
          setIsLoadedBetweenDatesInteractions(true);
        setIsLoadedBetweenDatesInteractionsLoading(false)

        },
        (error) => {
          setError(error);
        }
      )
    }
    
  }
  //

  //
   const options = {
    title: "Day pushed interactions records",
    vAxis: { title: "Reocrds Count" },
    hAxis: { title: "Day" },
    seriesType: "bars",
    series: { 5: { type: "line" } },
  };
  //
function ChangeDateState(){
  setIsLoadedBetweenDates(false)
}
  const handleChange = (event) => {
    if(dateFileter)
    setDateFileter(false);
    else
    setDateFileter(true);
    
  };
//
var today = new Date();
var dd = today.getDate();
var mm = today.getMonth() + 1;
var yyyy = today.getFullYear();

if (dd < 10) {
   dd = '0' + dd;
}

if (mm < 10) {
   mm = '0' + mm;
} 
    
today = yyyy + '-' + mm + '-' + dd;
//excel
function getSheetData(data, header) {
  var fields = Object.keys(data[0]);
  var sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header);
  return sheetData;
}

async function saveAsExcel() {
  var data = ItemsDateInteractionsDownload;
  let header = ["Agent Name", "Number", "Date", "CRM Disposition"];
  // console.log(data)
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);
    const sheetData = getSheetData(data, header);
    const totalColumns = sheetData[0].length;

    sheet1.cell("A1").value(sheetData);
    const range = sheet1.usedRange();
    const endColumn = String.fromCharCode(64 + totalColumns);
    sheet1.row(1).style("bold", true);
    sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
    range.style("border", true);
    return workbook.outputAsync().then((res) => {
      saveAs(res, new Date().toJSON().slice(0,10) + "" + "duraflexZoho.xlsx");
    });
  });
}

//
  return (
      <div>
        <h2 style={{textAlign: "center", margin: 20}}>CRM-ZOHO Synchronisation Statistics</h2>
        <div className={classes.root}>
      <AppBar position="static" style={{backgroundColor: "#607d8b"}}>
        <Tabs value={valuetabs} onChange={handleChangeTabs} aria-label="simple tabs example">
        <Tab label="Last 7 days Sync Stats" {...a11yProps(0)} />
        <Tab label="GSync Stats by Date Range" {...a11yProps(1)} />
          <Tab label="CRM Interactions" {...a11yProps(2)} />
          <Tab label="Download Reports" {...a11yProps(3)} />

        </Tabs>
      </AppBar>
      <TabPanel value={valuetabs} index={1}>
        <Typography variant='h6'>Select Date Range to get Grssl pushed day-to-day</Typography>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}} >
          <div>
            <input 
              required
                style={{
                  minWidth: 200,
                  maxWidth: 200,
                  minHeight: 35,
                  maxHeight: 50,
                  border: '2px solid black'
                }}
                type="date"
                value={startDate}
                max={today}
                onChange={e => setStartDate(e.target.value)}
              />
              </div>
              <div>
              <input
              required
                style={{
                  minWidth: 200,
                  maxWidth: 200,
                  minHeight: 35,
                  maxHeight: 50,
                  border: '2px solid black'
                }}
                type="date"
                value={endDate}
                max={today}
                onChange={e => setEndDate(e.target.value)}
              />
              </div>
              <div>
              <Button
              style={{
                minWidth: 200,
                maxWidth: 200,
                minHeight: 35,
                maxHeight: 50,
                border: '2px solid black',
                backgroundColor: 'lightblue',
                cursor: 'pointer'
              }}
              type="submit"
              onClick={() => findBetweenDate()}
            >Submit</Button>
          
              
              </div>
              {isLoadedBetweenDates ? (
                <Chart
          width="100%"
          height={600}
          chartType="ColumnChart"
          loader={<div>Loading Chart</div>}
          data={itemsBetweenDates}
          options={{
            title: 'Daily Zoho Integrations Report',
            chartArea: { width: '30%' },
            hAxis: {
              title: 'Date',
              minValue: 0,
              
            },
            vAxis: {
              title: 'Upload Count',
            },
          }}
          legendToggle
        />
              ):null}
              
              
        </div>
      </TabPanel>
      <TabPanel value={valuetabs} index={0}>
      <Typography variant='h6'>Grssl pushed last 7 days</Typography>

      <Chart
          width="100%"
          height={600}
          chartType="ColumnChart"
          loader={<div>Loading Chart</div>}
          data={items}
          options={{
            title: 'Daily Zoho Integrations Report',
            chartArea: { width: '30%' },
            hAxis: {
              title: 'Date',
              minValue: 0,
              
            },
            vAxis: {
              title: 'Upload Count',
            },
          }}
          legendToggle
        />
      </TabPanel>
      <TabPanel value={valuetabs} index={2}>
      <Typography variant='h6'>Select date range to see day-to-day calls pushed</Typography>

      <div  style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
      <div>
            <input 
              required
                style={{
                  minWidth: 200,
                  maxWidth: 200,
                  minHeight: 35,
                  maxHeight: 50,
                  border: '2px solid black'
                }}
                type="date"
                value={startDateInteractions}
                max={today}
                onChange={e => setStartDateInteractions(e.target.value)}
              />
              {/* {startDateInteractions} */}
            </div>
            <div>
            <input
            required
              style={{
                minWidth: 200,
                maxWidth: 200,
                minHeight: 35,
                maxHeight: 50,
                border: '2px solid black'
              }}
              type="date"
              value={endDateInteractions}
              max={today}
              onChange={e => setEndDateInteractions(e.target.value)}
            />
            </div>
            <div>
            <Button
            style={{
              minWidth: 200,
              maxWidth: 200,
              minHeight: 35,
              maxHeight: 50,
              border: '2px solid black',
              backgroundColor: 'tomato',
              cursor: 'pointer'
            }}
            type="submit"
            disabled={isLoadedBetweenDatesInteractionsLoading}
            onClick={() => findBetweenDateInteractions()}
          >Submit</Button>
         
            
            </div>
            {isLoadedBetweenDatesInteractionsLoading? (<>Loading....</>): null}
            {isLoadedBetweenDatesInteractions ? (
              <>
              <Chart
              chartType="ColumnChart"
              width="100%"
              height="400px"
              data={itemsBetweenDatesInteractions}
              options={options}
              />
              </>
            ): null}
            {/*  */}
        </div>
      </TabPanel>
      <TabPanel value={valuetabs} index={3}>
      <Typography variant='h6'>Select date range to download reports pushed</Typography>

      <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: 30,  marginBottom: 30}} >
                  <div>
          <input 
            required
              style={{
                minWidth: 200,
                maxWidth: 200,
                minHeight: 35,
                maxHeight: 50,
                border: '2px solid black'
              }}
              type="date"
              value={startDateInteractionsDownload}
              max={today}
              onChange={e => setStartDateInteractionsDownload(e.target.value)}
            />
            </div>
            <div>
            <input
            required
              style={{
                minWidth: 200,
                maxWidth: 200,
                minHeight: 35,
                maxHeight: 50,
                border: '2px solid black'
              }}
              type="date"
              value={endDateInteractionsDownload}
              max={today}
              onChange={e => setEndDateInteractionsDownload(e.target.value)}
            />
            {/* {endDateInteractionsDownload} */}
            </div>
            <div>
            <Button
            style={{
              minWidth: 200,
              maxWidth: 200,
              minHeight: 35,
              maxHeight: 50,
              border: '2px solid black',
              backgroundColor: 'lightgreen',
              cursor: 'pointer'
            }}
            variant='outlined'
            onClick={() => findBetweenDateInteractionsDownload()}
            disabled={isLoadedBetweenDatesInteractionsLoadingDownload}
          >Submit</Button>
         
            
            </div>
            </div>
            {isLoadedBetweenDatesDownload ? (<>
              <div style={{marginBottom: 30,display: "flex", justifyContent: "center", alignItems: "center"}}>
              <Button variant='outlined' onClick={saveAsExcel} disabled={isLoadedBetweenDatesInteractionsLoadingDownload}>
                Download
              </Button>
            </div>
            </>):( null)}
            {isLoadedBetweenDatesInteractionsLoadingDownload ? (<div style={{marginBottom: 30,display: "flex", justifyContent: "center", alignItems: "center"}}>
            Loading...<br/>
          Converting to Excel....<br/>
          Please Wait!
            </div>
            ): (null)}
                  </TabPanel>
    </div>

        
    </div>
  )
}