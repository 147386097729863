export default (permissions, userRole) => [
  {
    isVisible: permissions.dashboard.canViewAgentDashboard,
    link: '/dash360/agent',
    title: ''
  },
  {
    isVisible: permissions.dashboard.canViewManagerDashboard,
    link: '/dash360/restaurant-manager-dashboard',
    title: 'Dashboard'
  },
  {
    isVisible: permissions.dashboard.canViewAreaManagerDashboard,
    link: '/dash360/area-manager-dashboard',
    title: 'Dashboard'
  },
  {
    isVisible: permissions.dashboard.canViewL2Dashboard,
    link: '/dash360/L2-dashboard',
    title: 'Dashboard'
  },
  
  {
    isVisible: permissions.admin.canAccessAdminUploadPanel,
    link: '/manageAgents',
    title: 'Manage Agents'
  },
  // {
  //   isVisible: permissions.admin.canAccessAdminUploadPanel,
  //   link: '/recordings/cdr',
  //   title: 'CDR Reports'
  // },

  // {
  //   isVisible: permissions.admin.canAccessAdminUploadPanel,
  //   link: '/addNewUser',
  //   title: 'Add Users'
  // },

  
  {
    isVisible: permissions.admin.canAccessAdminUploadPanel,
    link: '/campaign',
    title: 'Campaign Dashboard'
  },
  
  // {
  //   isVisible:
  //     userRole === 'admin' || permissions.admin.canAccessAdminPrivileges,
  //   link: '/admin',
  //   title: ' Admin'
  // }
  
];
