export const COMMON = 'COMMON';
export const REDIRECT_TO = 'REDIRECT_TO';
export const SET_LOGIN_ACTION = 'SET_LOGIN_ACTION';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_PRODUCT_DATA = 'SET_PRODUCT_DATA';
export const SET_DISTRIBUTOR_ORDERS = 'SET_DISTRIBUTOR_ORDERS';
export const SET_DISTRIBUTOR_INVOICES = 'SET_DISTRIBUTOR_INVOICES';
export const SET_DELIVERY_PRICES = 'SET_DELIVERY_PRICES';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_CATEGORIES_NAMES_MAP = 'SET_CATEGORIES_NAMES';
