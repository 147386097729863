import React, { useEffect, useState } from 'react'

import {

    Card,
    CardContent,
    Grid,
    makeStyles,
    Button,


} from '@material-ui/core';
import moment from 'moment';

import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import axios from 'axios'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ScheduleIcon from '@material-ui/icons/Schedule';
import QueuePlayNextOutlinedIcon from '@material-ui/icons/QueuePlayNextOutlined';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
import DialerSipOutlinedIcon from '@material-ui/icons/DialerSipOutlined';
import CallMissedOutgoingOutlinedIcon from '@material-ui/icons/CallMissedOutgoingOutlined';
import AddIcCallOutlinedIcon from '@material-ui/icons/AddIcCallOutlined';
import PhoneMissedOutlinedIcon from '@material-ui/icons/PhoneMissedOutlined';
import MicOffOutlinedIcon from '@material-ui/icons/MicOffOutlined';
import PhoneDisabledOutlinedIcon from '@material-ui/icons/PhoneDisabledOutlined';
import SettingsPhoneOutlinedIcon from '@material-ui/icons/SettingsPhoneOutlined';
import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined';
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';
import Download from '../DownloadReport'
import { CAMPAIGN } from 'src/modules/dashboard-360/utils/endpoints'
import Showmodal from './showjobdetails'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        flexGrow: 1,
        margin: '1rem 2rem'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 260,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


const Campaign = (props) => {
    const classes = useStyles();

    const [campaigns, setCampaigns] = useState("")
    const [selectedCamapign, setSelectedCampaign] = useState("")
    const [campaigndata, setCampaignData] = useState([])
    const [downloaddata, setDownloadData] = useState([])
    const [jobdata, setJobData] = useState([])
    const [modaldata, setModaldata] = useState([])
    const [jobdata1,setJobdata1]= useState([])
    const [show, setShow] = useState(false)

    const JobColumns = [
        {
            headerName: 'SL NO',
            field: 'id',
            width: 150

        },
        {
            headerName: 'Mobile Number',
            field: 'mobileNumber',
            width: 150

        },

        {
            headerName: 'Phone Number',
            field: 'phone',
            width: 150

        },
        {
            headerName: 'Disposition',
            field: 'Disposition',
            width: 150

        },
        
        {
            headerName: 'Attempts',
            field: 'Attempts',
            width: 150
        },
        {
            headerName: 'Job Status',
            field: 'jobStatus',
            width: 150
        },
        {
            headerName: 'Main Disposition',
            field: 'mainDisposition',
            width: 150
        },
        {
            headerName: 'Customer Name',
            field: 'Customer_Name',
            width: 150
        },
        {
            headerName: 'Customer Number',
            field: 'Customer_contact_number',
            width: 150
        },
        {
            headerName: 'Channel',
            field: 'Channel',
            width: 150
        },
        {
            headerName: 'Product Model',
            field: 'Product_Model',
            width: 150
        },
        {
            headerName: 'Product Dimension',
            field: 'Product_Dimensions',
            width: 150
        },
        {
            headerName: 'Satisfied',
            field: 'satisfied',
            width: 150
        },
        {
            headerName: 'Issue',
            field: 'issue',
            width: 150
        },
        {
            headerName: 'Review Share',
            field: 'Review_Share',
            width: 150
        },
        {
            headerName: 'Registered Warranty',
            field: 'Registered_Warranty',
            width: 150
        },
        
        {
            headerName: 'Not Registered Warranty',
            field: 'warrantyProcedure',
            width: 150
        },
        {
            headerName: 'Call Back Requested',
            field: 'requestCallBack',
            width: 150
        },
        {
            headerName: 'Call Back Time',
            field: 'callbackTime',
            width: 150
        },
        // {
        //     headerName: 'Main Disposition',
        //     field: 'mainDisposition',
        //     width: 150
        // },
        // {
        //     headerName: 'Main Disposition',
        //     field: 'mainDisposition',
        //     width: 150
        // },
        {
            headerName: 'Created At',
            field: 'createdAt',
            width: 200
        },
    


        {
            headerName: 'Agent ID',
            field: 'INTERACTION_AGENTID',
            width: 150
        },
        {
            headerName: 'Agent Name',
            field: 'agentName',
            width: 150
        },
        {
            headerName: 'Agent SIP_ID',
            field: 'sip_id',
            width: 150
        },
        {
            headerName: 'Agent Type',
            field: 'agent_type',
            width: 150
        },
        
    ];
    const getCampaigns = () => {
        axios.get(`${CAMPAIGN}/campaign/getAllCampaign`)
            //  axios.get(`http://192.168.4.44:62010/campaign/getAllCampaign`)
            .then((res) => {
                setCampaigns(res.data.Record.reverse())

            })
            .catch((err) => {
                console.log("Failed to get campaigns values")
            })
    }
    const handleClose = () => {

        setShow(false)
    }
    const getCampaignDetails = () => {
        const data = {
            "ivrCampaignName": localStorage.getItem('campaign')
        }

        axios.post(`${CAMPAIGN}/channel/getBycampaign`, data)
            // axios.post(`http://192.168.4.44:62010/channel/getBycampaign`,data)
            .then((response) => {

                response.data.counts[0].Campaignstartdate = moment(response.data.counts[0].Campaignstartdate).format('Do MMMM  YYYY, h:mm:ss a');
                response.data.counts[0].Campaignenddate = moment(response.data.counts[0].Campaignenddate).format('Do MMMM  YYYY, h:mm:ss a');
                console.log(response.data)
                setCampaignData(response.data.counts)
            })
            .catch((err) => {
                console.log(err.message, "failed to fetch campaign details")
            })
    }
    const getCampaignDetailsByName = () => {
        const data = {
            "ivrCampaignName": localStorage.getItem('campaign')
        }

        axios.post(`${CAMPAIGN}/channel/getbycampaignname`, data)
            //  axios.post(`http://192.168.4.44:62010/channel/getbycampaignname`,data)
            .then((response) => {

                // response.data.counts[0].Campaignstartdate = moment(response.data.counts[0].Campaignstartdate).format('Do MMMM  YYYY, h:mm:ss a');
                // response.data.counts[0].Campaignenddate = moment(response.data.counts[0].Campaignenddate).format('Do MMMM  YYYY, h:mm:ss a');
                console.log(response.data);
                // response.data.map((ele) =>{

                // })
                response.data.Record.map((ele) => {
                    delete ele._id;
                    // delete ele.Disposition;
                    ele.Disposition = ele.Disposition.split(" ").join("");
                    console.log(ele.disposition);
                    ele.Call_StartTime = ele.Call_StartTime.split(" ").join("");
                    ele.Call_EndTime = ele.Call_EndTime.split(" ").join("");
                    //var formatted=   new Date(ele.Call_Duration*1000).toISOString().substr(11, 8);
                    var formatted = moment.utc(ele.Call_Duration * 1000).format('HH:mm:ss');
                    ele.Call_Duration = formatted;
                    //  if (ele.hasOwnProperty("CRMDISPOSITION")) {
                    // return (
                       
                       
                    // ele.subDisposition = ele.CRMDISPOSITION.subDisposition,
                    ele.sip_id = ele.CRMDISPOSITION.sip_id;
                    ele.agent_type = ele.CRMDISPOSITION.agent_type;
                    ele.agent_id = ele.CRMDISPOSITION.agent_id;
                    ele.agentName = ele.CRMDISPOSITION.agentName;
                    // ele.response = ele.CRMDISPOSITION.response;
                    // ele.guestName = ele.CRMDISPOSITION.guestName;
                    ele.Call_Status=ele.CRMDISPOSITION.Call_Status;
                    ele.main_Disposition = ele.CRMDISPOSITION.main_Disposition;
                    ele.Request_Callback=ele.CRMDISPOSITION.Request_Callback;
                    ele.Reason=ele.CRMDISPOSITION.Reason;
                    ele.Escalated=ele.CRMDISPOSITION.Escalated;
                    ele.Reason_for_Escalation=ele.CRMDISPOSITION.Reason_for_Escalation;
                    delete ele.CRMDISPOSITION;
                    delete ele.IVR_Option_Selected;
                    delete ele.CustomerName;
                    delete ele.outlet;
                    delete ele.region;
                    delete ele.amname;
                    delete ele.orderID;
                    
                    return response.data.Record;
                })

                setDownloadData(response.data.Record);
            })
            .catch((err) => {
                console.log(err.message, "failed to fetch campaign details")
            })
    }

    const getJobtable = () => {
        const data = {
            "ivrCampaignName": localStorage.getItem('campaign')
        }

        axios.post(`${CAMPAIGN}/channel/getJobreportExcel`, data)
            //  axios.post(`http://192.168.4.44:62010/channel/getbycampaignname`,data)
            .then((response) => {

                
                console.log(response.data);
                
                var i = 0;
                response.data.Record.map((ele) => {
                    i = i + 1;
                    // delete ele._id;

                    var formatted = moment.utc(ele.Call_Duration * 1000).format('HH:mm:ss');
                    ele.Call_Duration = formatted;
                    var formatted1 = moment.utc(ele.createdAt).format('DD-MM-yyyy HH:mm:ss');
                    ele.createdAt = formatted1;
                    ele.id = i;
                    if (ele.jobStatus === "1") {
                        ele.jobStatus = "Job Completed"
                    }
                    if (ele.jobStatus === "0") {
                        ele.jobStatus = "Job Not Completed"
                    }
                    if (ele.escalated === true) {
                        ele.escalated = "escalated"
                    }

                    if(Object.keys(ele.CRMDISPOSITION).length >0){
                        ele.mainDisposition = ele.CRMDISPOSITION.mainDisposition
                        ele.response = ele.CRMDISPOSITION.response
                        ele.requestCallBack = ele.CRMDISPOSITION.requestCallBack
                        ele.Customer_Name = ele.CRMDISPOSITION.Customer_Name
                        ele.Customer_contact_number = ele.CRMDISPOSITION.Customer_contact_number
                        // ele.escalated = ele.CRMDISPOSITION.escalated
                        ele.Channel = ele.CRMDISPOSITION.Channel

                        ele.Product_Model = ele.CRMDISPOSITION.Product_Model
                        ele.Product_Dimensions = ele.CRMDISPOSITION.Product_Dimensions
                        ele.satisfied = ele.CRMDISPOSITION.satisfied

                        ele.Review_Share = ele.CRMDISPOSITION.Review_Share
                        ele.Registered_Warranty = ele.CRMDISPOSITION.Registered_Warranty
                        ele.agentRemarks = ele.CRMDISPOSITION.agentRemarks

                        if(ele.satisfied === 'No'){
                            ele.issue =ele.CRMDISPOSITION.issue
                        }
                        if(ele.Registered_Warranty === 'No'){
                            ele.warrantyProcedure =ele.CRMDISPOSITION.Warranty_Procedure
                        }
                        if(ele.requestCallBack === 'Yes'){
                            ele.callbackTime =ele.CRMDISPOSITION.callBackTime
                        }

                    }
                   

                    return response.data.Record;
                })

                setJobData(response.data.Record);
            })
            .catch((err) => {
                console.log(err.message, "failed to fetch campaign details")
            })
    }
    const showProfile = (data) => {
        console.log("data", data.row)
        var axios = require('axios');
        data = JSON.stringify({ "accountNumber": data.row.AccountCode });

        var config = {
            method: 'post',
            url: `${CAMPAIGN}/channel/getJobreportByid`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log("modaldata", response.data);
                if (response.data.Record.length > 0) {
                    response.data.Record.map((ele) => {

                        // delete ele._id;

                        var formatted = moment.utc(ele.Call_Duration * 1000).format('HH:mm:ss');
                        ele.Call_Duration = formatted;
                        var formatted1 = moment.utc(ele.createdAt).format('DD-MM-yyyy HH:mm:ss');
                        ele.createdAt = formatted1;

                        if (ele.jobStatus === "1") {
                            ele.jobStatus = "Job Completed"
                        }
                        if (ele.jobStatus === "0") {
                            ele.jobStatus = "Job Not Completed"
                        }
                        if (ele.Escalated === true) {
                            ele.Escalated = "escalated"
                        }
                        return response.data.Record;
                    })
                    setModaldata(response.data.Record);
                    setShow(true)
                }
            })
            .catch(function (error) {
                console.log(error);
            });


    }

  

    useEffect(() => {
        getCampaigns()



    }, [])

    useEffect(() => {
        if (localStorage.getItem('campaign')) {
          
            const interval = setInterval(async () => {
                if(window.location.href.includes('campaignreport')){
                    getCampaignDetails()
                    getCampaignDetailsByName()
                    getJobtable()
                }
                
            }, 7000);
            console.log(interval)
        }
    }, [selectedCamapign])



    const handleChange = (e) => {
        localStorage.setItem("campaign", e.target.value)
        setSelectedCampaign(e.target.value)
    }

    

    return (<div>
        {/* <h3 style={{ textAlign: 'center' }}>Campaign Monitoring</h3> */}

        <Grid container spacing={3}>
            {/* <Grid item lg={12} md={12} xs={12}></Grid> */}
            <Grid item lg={5} md={12} xs={12}></Grid>
            <Grid item lg={2} md={12} xs={12}>
                <FormControl variant="outlined" className={classes.formControl} >
                    <InputLabel id="demo-simple-select-outlined-label">Campaign</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={selectedCamapign}
                        onChange={handleChange}
                        label="Campaign"
                        autoWidth="true"
                    >
                        {
                            campaigns.length > 0 ? (
                                campaigns.map((ele) => {
                                    return (<MenuItem value={ele.campaign_name}>{ele.campaign_name}</MenuItem>)
                                })
                            ) : (null)
                        }

                    </Select>
                </FormControl>
            </Grid>
            <Grid item lg={5} md={12} xs={12}></Grid>
            {campaigndata.length > 0 ? <>
                <Grid item lg={3} md={12} xs={12}>
                    <Card style={{ backgroundColor: '#A52A2A', borderRadius: '12px', fontSize: '13px' }}>
                        <CardContent><h3 style={{ color: 'white', textAlign: 'center' }}><b><ScheduleIcon /> Start date : </b>{campaigndata[0].Campaignstartdate}</h3></CardContent>
                    </Card>
                </Grid>

                <Grid item lg={3} md={12} xs={12}>
                    <Card style={{ backgroundColor: '#696969', borderRadius: '12px', fontSize: '13px' }}>
                        <CardContent><h3 style={{ color: 'white', textAlign: 'center' }}><b><ScheduleIcon /> End date : </b>{campaigndata[0].Campaignenddate}</h3></CardContent>
                    </Card>

                </Grid>

                <Grid item lg={3} md={12} xs={12}>
                    <Card style={{ backgroundColor: '#800080', borderRadius: '12px', fontSize: '13px' }}>
                        <CardContent><h3 style={{ color: 'white', textAlign: 'center' }}><b><QueuePlayNextOutlinedIcon /> Queue: </b>{campaigndata[0].queue}</h3></CardContent>
                    </Card>

                </Grid>


                <Grid item lg={3} md={12} xs={12}>
                    <Card style={{ backgroundColor: '#66CDAA', borderRadius: '12px', justifyContent: 'center', fontSize: '13px' }}>
                        <CardContent><h3 style={{ color: 'white', textAlign: 'center' }}><b><BackupOutlinedIcon /> Total Records Uploaded : </b>{campaigndata[0].totalRecords}</h3></CardContent>
                    </Card>
                </Grid>

                <Grid item lg={3} md={12} xs={12}>
                    <Card style={{ backgroundColor: '#191970', borderRadius: '12px', fontSize: '13px' }}>
                        <CardContent><h3 style={{ color: 'white', textAlign: 'center' }}><b><DialerSipOutlinedIcon /> Dailed records Count: </b>{campaigndata[0].DailedCountrecordsCount}</h3></CardContent>
                    </Card>
                </Grid>

                <Grid item lg={3} md={12} xs={12}>
                    <Card style={{ backgroundColor: '#6B8E23', borderRadius: '12px', fontSize: '13px' }}>
                        <CardContent><h3 style={{ color: 'white', textAlign: 'center' }}><b><CallMissedOutgoingOutlinedIcon /> NotDailed records Count: </b>{campaigndata[0].NotDailedrecordsCount}</h3></CardContent>
                    </Card>
                </Grid>


                <Grid item lg={3} md={12} xs={12}>
                    <Card style={{ backgroundColor: '#006400', borderRadius: '12px', fontSize: '13px' }}>
                        <CardContent><h3 style={{ color: 'white', textAlign: 'center' }}><b><AddIcCallOutlinedIcon /> Answered records Count: </b>{campaigndata[0].AnsweredrecordCount}</h3></CardContent>
                    </Card>
                </Grid>

                <Grid item lg={3} md={12} xs={12}>
                    <Card style={{ backgroundColor: '#BC8F8F', borderRadius: '12px', fontSize: '13px' }}>
                        <CardContent><h3 style={{ color: 'white', textAlign: 'center' }}><b><PhoneMissedOutlinedIcon /> Not Answered records Count: </b>{campaigndata[0].NoAnsweredrecordCount}</h3></CardContent>
                    </Card>
                </Grid>

                <Grid item lg={3} md={12} xs={12}>
                    <Card style={{ backgroundColor: '#FA8072', borderRadius: '12px', fontSize: '13px' }}>
                        <CardContent><h3 style={{ color: 'white', textAlign: 'center' }}><b><PhoneDisabledOutlinedIcon /> Failed calls records Count: </b>{campaigndata[0].FailerrecordCount}</h3></CardContent>
                    </Card>
                </Grid>

                <Grid item lg={3} md={12} xs={12}>
                    <Card style={{ backgroundColor: '#708090', borderRadius: '12px', fontSize: '13px' }}>
                        <CardContent><h3 style={{ color: 'white', textAlign: 'center' }}><b><MicOffOutlinedIcon /> Busy calls records Count: </b>{campaigndata[0].BusyrecordCount}</h3></CardContent>
                    </Card>
                </Grid>

                <Grid item lg={3} md={12} xs={12}>
                    <Card style={{ backgroundColor: '#2F4F4F', borderRadius: '12px', fontSize: '13px' }}>
                        <CardContent><h3 style={{ color: 'white', textAlign: 'center' }}><b><SettingsPhoneOutlinedIcon /> Congestion calls records Count: </b>{campaigndata[0].CongestionrecordCount}</h3></CardContent>
                    </Card>
                </Grid>

                <Grid item lg={3} md={12} xs={12}>
                    <Card style={{ backgroundColor: '#DAA520', borderRadius: '12px', fontSize: '13px' }}>
                        <CardContent ><h3 style={{ color: 'white', textAlign: 'center' }}><b> <StorageOutlinedIcon /> Job complete records Count: </b>{campaigndata[0].jobcompleterecordcount}</h3></CardContent>
                    </Card>
                </Grid>
                <Grid item lg={3} md={12} xs={12}>
                    <Card style={{ backgroundColor: '#808000', borderRadius: '12px', fontSize: '13px' }}>
                        <CardContent><h3 style={{ color: 'white', textAlign: 'center' }}><b> <StorageOutlinedIcon /> Job not complete records Count: </b>{campaigndata[0].jobnotcompleterecordcount}</h3></CardContent>
                    </Card>
                </Grid>
                <Grid item lg={3} md={12} xs={12}>
                    <Card style={{ backgroundColor: '#0000CD', borderRadius: '12px', fontSize: '13px' }}>
                        <CardContent><h3 style={{ color: 'white', textAlign: 'center' }}><b><ReplayOutlinedIcon /> Retries : </b>{campaigndata[0].retries}</h3></CardContent>
                    </Card>
                </Grid>
                <Grid item lg={3} md={12} xs={12}>
                    <Card style={{ backgroundColor: '#696969', borderRadius: '12px', fontSize: '13px' }}>
                        <CardContent><h3 style={{ color: 'white', textAlign: 'center' }}><b> <StorageOutlinedIcon /> Campaign Status: </b>{campaigndata[0].status}</h3></CardContent>
                    </Card>
                </Grid>
                <Grid item lg={3} md={12} xs={12}>
                    <Button>
                        <Download DownloadData={downloaddata} />
                    </Button>
                </Grid>


                {jobdata.length > 0 && <Grid item lg={12} md={12} xs={12}>
                    <Card >
                        <CardContent style={{ 'height': '400px' }}>
                            <h4>Job Records</h4>
                            <DataGrid rows={jobdata} components={{
                                Toolbar: GridToolbar,

                            }} columns={JobColumns} pageSize={5}
                                onRowClick={showProfile}
                                pagination />

                        </CardContent>
                    </Card>
                </Grid>
                }
                {show === true ? <Showmodal show={show} modaldata={modaldata} handleClose={handleClose} /> : <></>}
            </> : <></>}
        </Grid>
    </div >)
}

export default Campaign