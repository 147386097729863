import React, { useEffect, useState } from 'react';




const Footer = ({

}) => {







 
  return (
   <div    style={{ 'margin-top': '1rem',
   'padding': '1rem',
   // 'background-color': 'rgb(235, 195, 64)',
   'position': 'fixed',
   'bottom': '0',
   'left': '0',
   'width': '100%',
   'text-align' : 'center', 
   }}>
 
<div className="copyright text-center text-xl-left text-muted">
          © {new Date().getFullYear()}{" "}
          <a
            className="font-weight-bold ml-1"
            href="https://www.grssl.com/"
            rel="noopener noreferrer"
            target="_blank"
             style={{'color':'red'}}
          >
            Powered By Grassroots

            {/* <img
      alt="Logo"
      src="/static/gr3.png"
      // {...props}
      style={{ height: '20px',width:'100px' }}
    /> */}
          </a>
        </div>
   </div>
  );
};


export default Footer
