import React, { useState,useEffect } from 'react';
import { Button } from '@material-ui/core';
import Download from '../DownloadReport'
import { Grid,Card,CardContent,FormControl,InputLabel,Select,MenuItem } from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ExcelReport from 'src/components/ExcelReport.js'
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import InteractionDate from './DaterangeReport'
import CircularProgress from '@material-ui/core/CircularProgress'
import DaterangeReport1 from './DaterangeReport_form'
import { CAMPAIGN } from 'src/modules/dashboard-360/utils/endpoints'

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 260,
    },
  }));
const Report = () => {
    const classes = useStyles();
    const [dialerdata, setDialerData] = useState([])
    const [formdata, setFormData] = useState([])
    const [escalateddata, setEscalatedData] = useState([])
    const [requestcallbackdata, setCallbackData] = useState([])
    const [dialerDateRange,setDialerDateRange] = useState([])
    const [loader, setLoader] = useState(false)
    const [loader1, setLoader1] = useState(false)
    const [formdataRange,setFormDataRange]= useState([])
    const [selectedCamapign, setSelectedCampaign] = useState("")
    const [campaigns, setCampaigns] = useState("")
    const [report,setReport]= useState("")
    const [downloadData,setDownloadData]= useState("")
    const [name,setName]= useState("")
    

    useEffect(() => {
        getCampaigns()

    }, [])

    const getCampaigns = () => {
        axios.get(`${CAMPAIGN}/campaign/getAllCampaign`)
            //  axios.get(`http://192.168.4.44:62010/campaign/getAllCampaign`)
            .then((res) => {
                setCampaigns(res.data.Record.reverse())

            })
            .catch((err) => {
                console.log("Failed to get campaigns values")
            })
    }

    const handleChange = (e) => {
        
        setSelectedCampaign(e.target.value)
    }

    const handleChangeReport = (e)=>{
        setDownloadData("")
        setReport(e.target.value)
        if(e.target.value === 'Dialer_Report'){
            handleButtonDailer()
        }else if(e.target.value ==='Form_Report'){
            handleButtonFormReport()
        }else if(e.target.value === 'No_Called_Report'){
            handleButtonNoCallData()
        }
    }

    const showProfile=()=>{

    }


 
    const handleButtonDailer = () => {
        var dialerdata = []
        const data={
            "ivrCampaignName":selectedCamapign
        }
        axios.post(`/crm-route/Campaigndialerreports`,data)

            .then((response) => {
                console.log("dailerreports",response);
                response.data.Record.map((ele) => {

                    ele.StatusCall = ele.Disposition;
                    ele.Call_StartTime = ele.Call_StartTime;
                    ele.Call_EndTime = ele.Call_EndTime;
                    var formatted = moment.utc(ele.Call_Duration * 1000).format('HH:mm:ss');
                    ele.Call_Duration = formatted;
                    ele.Outlet = ele.outlet;
                    if (ele.Disposition === 'NO ANSWER') {
                        ele.FailureReason = 'The call was not answered';
                    } else if (ele.Disposition === 'FAILED') {
                        ele.FailureReason = 'The channel attempted to dial but the callfailed';
                    } else if (ele.Disposition === 'BUSY') {
                        ele.FailureReason = 'The channel attempted to dial but the remote party was busy';
                    } else if (ele.Disposition === 'CONGESTION') {
                        ele.FailureReason = 'The channel attempted to dial but the remote party was congested';
                    }  else if (ele.Disposition === 'ANSWERED') {
                        ele.FailureReason = 'The call was answered';
                    }else {
                        ele.FailureReason = 'Not Called';
                    }
                    delete ele.CRMDISPOSITION;
                    delete ele.createdAt;
                    delete ele.updatedAt;
                    delete ele.Disposition;
                    delete ele._id;
                    delete ele.outlet;
                    dialerdata.push({
                        PhoneNo: ele.phone,
                        StatusCall: ele.StatusCall,
                        Agent: ele.AgentName,
                        DateAndTime: ele.Call_StartTime,
                        Duration: ele.Call_Duration,
                        Uniqueid: ele.UniqueID,
                        // FailureCode: "",
                        CallStatus: ele.FailureReason,
                        // MobileNumber: ele.mobileNumber,
                        // Reference_ID:ele.iEC ,
                        // Customer_Name: ele.companyName,
                        
                    
                        
                    })
                    return dialerdata;
                })
                setDownloadData(dialerdata);
                setName(`Dialer Data - ${new Date()}`)

                //console.log(response.data)
                // alert(`file uploaded succesfully`)
            })
            .catch((err) => {
                console.log(err)
            })
    }
    const handleButtonFormReport = () => {
        var formdata = []
        const data={
            "ivrCampaignName":selectedCamapign
        }
        axios.post(`/crm-route/Campaignformreports`,data)
            .then((response) => {
                console.log("formdata", response);
                let i=0;
                response.data.Record.map((ele) => {
                   
                   if(ele.CRMDISPOSITION){
                    //    i=i+1
                    // ele.CRMDISPOSITION.id= i
                    let obj = {
                        CalledDate : ele.createdAt.slice(0,10),
                        Agent_Name : ele.CRMDISPOSITION.agentName,
                        Customer_Name :  ele.CRMDISPOSITION.Customername,
                        Customer_Contact_No : ele.CRMDISPOSITION.CallerNumber,
                        Connectivity:ele.CRMDISPOSITION.mainDisposition,
                        Product_Name:ele.CRMDISPOSITION.Product_Name,
                        Channel:ele.CRMDISPOSITION.Channel,
                        Customer_Satisfied : ele.CRMDISPOSITION.Primary_satisfaction,
                        Customer_satisfaction_reason : ele.CRMDISPOSITION.satisfied_reason,
                        customer_issue : ele.CRMDISPOSITION.Customer_issue,
                        family_usage : ele.CRMDISPOSITION.family_experience,
                        family_satisfaction : ele.CRMDISPOSITION.family_satisfaction,
                        family_issue : ele.CRMDISPOSITION.Family_issue,
                        //Issue : ele.CRMDISPOSITION.issue,
                        Review_Share : ele.CRMDISPOSITION.Review_Share,
                       
                        Registered_Warranty:ele.CRMDISPOSITION.Registered_Warranty,
                        
                       
                       
                        
                        Agent_Remarks:ele.CRMDISPOSITION.agentRemarks,
                        Call_Duration : ele.Call_Duration

                    }
                    for (const prop in obj) {
                        // console.log(`obj.${prop} = ${obj[prop]}`);
                        if(obj[prop] === '' || obj[prop]=== "" || obj[prop] === undefined){
                            obj[prop]= null
                        }
                      }
                    
                    formdata.push(obj)
                   } 
                })

                // console.log()
                console.log(formdata,"finallllll form data")
                setDownloadData(formdata);
                setName(`Form Data - ${new Date()}`)
                //console.log(response.data)
                // alert(`file uploaded succesfully`)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleButtonNoCallData = () => {
        var formdata = []
        const data={
            "ivrCampaignName":selectedCamapign
        }
        axios.post(`/crm-route/CampaignNotCalledNumbers`,data)
            .then((response) => {
                console.log("no called data", response.data.data);
                
                    console.log("i am here")
                    response.data.data.map((ele)=>{
                        let obj ={
                            Name : ele.name,
                            phone : ele.phone,
                            Product : ele.product,
                            Channel : ele.channel,
                            Productlink : ele.productlink
                        }
                        for (const prop in obj) {
                            if(obj[prop] === '' || obj[prop]=== ""){
                            obj[prop]= null
                            }
                    }
                    formdata.push(obj)
                    
                    })
                    console.log(formdata,"formdata")
                    setDownloadData(formdata);
                    setName(`NotCalled Data - ${new Date()}`)
                    
                
                
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleButtonCallBackData = () => {
        var callback = []
        axios.get(`/crm-route/requestedcallback`)
            .then((response) => {
                console.log("callback", response)
                response.data.Record.map((ele) => {
                    delete ele._id;
                    delete ele.ivrCampaignName;
                   
                    ele.Call_Status = ele.CRMDISPOSITION.Call_Status;
                    ele.main_Disposition = ele.CRMDISPOSITION.main_Disposition;
                    ele.Reason = ele.CRMDISPOSITION.Reason;
                    ele.Request_Callback = ele.CRMDISPOSITION.Request_Callback;
                    ele.Escalated = ele.CRMDISPOSITION.Escalated;
                    ele.Reason_for_Escalation = ele.CRMDISPOSITION.Reason_for_Escalation;
                    ele.AgentName = ele.CRMDISPOSITION.agentName;
                   
                    delete ele.CRMDISPOSITION;
                    callback.push({
                        AttemptCallBack: ele.attemptCallBack,
                        
                        CallBackTime: ele.defaultCallBackTime,
                        MobileNumber: ele.mobileNumber,
                        Reference_ID:ele.iEC ,
                        Customer_Name: ele.companyName,
                        city: ele.city,
                        state: ele.state,
                        email: ele.email,
                       
                        PhoneNO: ele.phone,
                       
                        AgentName: ele.AgentName,
                        Call_Status :ele.Call_Status,
                        main_Disposition : ele.main_Disposition,
                        Reason : ele.Reason,
                        Request_Callback :ele.Request_Callback,
                        Escalated : ele.Escalated,
                        Reason_for_Escalation :ele.Reason_for_Escalation,

                    })
                    return callback;
                })
                setCallbackData(callback)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const getData1 = (date, enddate) => {
        console.log(date, enddate)
        let value = moment(date).format().slice(0, 10)
        let value2 = moment(enddate).format().slice(0, 10)
        console.log(date,enddate,"dates")
        const data = {
            "startDate": value,
            "endDate": value2
        }

        axios.post(`/crm-route/formreports`,data)
        .then((response) => {
            console.log("formdata", response);
            let i=0;
            response.data.Record.map((ele) => {
               
               if(ele.CRMDISPOSITION){
                //    i=i+1
                // ele.CRMDISPOSITION.id= i
                let obj = {
                    CalledDate : ele.createdAt.slice(0,10),
                    Agent_Name : ele.CRMDISPOSITION.agentName,
                    Customer_Name :  ele.CRMDISPOSITION.Customername,
                    Customer_Contact_No : ele.CRMDISPOSITION.CallerNumber,
                    Connectivity:ele.CRMDISPOSITION.mainDisposition,
                    Product_Name:ele.CRMDISPOSITION.Product_Name,
                    Channel:ele.CRMDISPOSITION.Channel,
                    Satisfied : ele.CRMDISPOSITION.satisfied,
                    Review_Share : ele.CRMDISPOSITION.Review_Share,
                    Issue : ele.CRMDISPOSITION.issue,
                    Registered_Warranty:ele.CRMDISPOSITION.Registered_Warranty,
                    Not_Registered:ele.CRMDISPOSITION.Warranty_Procedure,
                    Agent_Remarks:ele.CRMDISPOSITION.agentRemarks,
                    Call_Duration : ele.Call_Duration,
                }
                for (const prop in obj) {
                    // console.log(`obj.${prop} = ${obj[prop]}`);
                    if(obj[prop] === '' || obj[prop]=== ""){
                        obj[prop]= null
                    }
                  }
                
                formdata.push(obj)
               }
                
                
             
               
            })

            // console.log()
            setFormDataRange(formdata);
            setLoader1(false)
            //console.log(response.data)
            // alert(`file uploaded succesfully`)
        })
        .catch((err) => {
            console.log(err)
        })
    }

    const getData = (date, enddate) => {
        console.log(date, enddate)
        let value = moment(date).format().slice(0, 10)
        let value2 = moment(enddate).format().slice(0, 10)
        console.log(date,enddate,"dates")
        const data = {
            "startDate": value,
            "endDate": value2
        }

        axios.post(`/crm-route/dailerreports`, data)
            .then((response) => {
                setLoader(false)
                console.log(response.data, "records")
                response.data.Record.map((ele) => {

                    ele.StatusCall = ele.Disposition;
                    ele.Call_StartTime = ele.Call_StartTime;
                    ele.Call_EndTime = ele.Call_EndTime;
                    var formatted = moment.utc(ele.Call_Duration * 1000).format('HH:mm:ss');
                    ele.Call_Duration = formatted;
                    ele.Outlet = ele.outlet;
                    if (ele.Disposition === 'NO ANSWER') {
                        ele.FailureReason = 'The call was not answered';
                    } else if (ele.Disposition === 'FAILED') {
                        ele.FailureReason = 'The channel attempted to dial but the callfailed';
                    } else if (ele.Disposition === 'BUSY') {
                        ele.FailureReason = 'The channel attempted to dial but the remote party was busy';
                    } else if (ele.Disposition === 'CONGESTION') {
                        ele.FailureReason = 'The channel attempted to dial but the remote party was congested';
                    }  else if (ele.Disposition === 'ANSWERED') {
                        ele.FailureReason = 'The call was answered';
                    }else {
                        ele.FailureReason = 'Not Called';
                    }
                    delete ele.CRMDISPOSITION;
                    delete ele.createdAt;
                    delete ele.updatedAt;
                    delete ele.Disposition;
                    delete ele._id;
                    delete ele.outlet;
                    dialerdata.push({
                        PhoneNo: ele.phone,
                        StatusCall: ele.StatusCall,
                        Agent: ele.AgentName,
                        DateAndTime: ele.Call_StartTime,
                        Duration: ele.Call_Duration,
                        Uniqueid: ele.UniqueID,
                        // FailureCode: "",
                        CallStatus: ele.FailureReason,
                        MobileNumber: ele.mobileNumber,
                        // Reference_ID:ele.iEC ,
                        // Customer_Name: ele.companyName,
                        
                    
                        
                    })
                    return dialerdata;
                })
                setDialerDateRange(dialerdata);
                
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleButtonescalationReport = () => {
        var escalationdata = []
        axios.get(`/crm-route/escalatedreports`)
            .then((response) => {
                console.log("escalation", response);
                response.data.Record.map((ele) => {
                    delete ele._id;
                    ele.Date = ele.updatedAt;
                    ele.AMname = ele.amname;
                    ele.GuestNumber = ele.mobileNumber;
                    ele.MainDisposition= ele.MainDisposition;
                    ele.Reason=ele.Reason;
                    ele.Reason_for_Escalation=ele.Reason_for_Escalation

                    // ele.GuestName = ele.CustomerName;
                    ele.Outlet = ele.outlet;
                    // ele.Remark = ele.Remarks;
                    ele.CallConnectivity = ele.CRMDISPOSITION.mainDisposition;
                    delete ele.updatedAt;
                    delete ele.CRMDISPOSITION;
                    delete ele._id;
                    delete ele.phone;
                    delete ele.Disposition;
                    delete ele.mobileNumber;
                    delete ele.amname;
                    delete ele.orderID;
                    delete ele.ivrCampaignName;
                    delete ele.createdAt;
                    delete ele.CustomerName;
                    delete ele.callConnectedOrNOt;
                    delete ele.DinningMode;
                    delete ele.DeliveryPartner;
                    delete ele.OverallScore;
                    delete ele.DeliveryIssue;
                    delete ele.QualityIssue;
                    delete ele.QuantityIssue;
                    delete ele.Reccomend;
                    // delete ele.Suggestions;
                    delete ele.Remarks;
                    delete ele.UniqueID;
                    delete ele.AgentName;
                    delete ele.INTERACTION_AGENTID;
                    delete ele.CDR_CallerID;
                    delete ele.Call_StartTime;
                    delete ele.Call_EndTime;
                    delete ele.Call_Duration;
                    delete ele.Call_Attempts;
                    delete ele.IVR_Option_Selected;
                    delete ele.outlet;
                    var dateFormat = 'DD-MM-YYYY HH:mm:ss';
                    var date = moment.utc(ele.Date);

                    var localeDate = date.local();

                    ele.Date = localeDate.format(dateFormat);

                    escalationdata.push({
                        Date: ele.Date,
                        Reference_ID:ele.iEC ,
                        Customer_Name: ele.companyName,
                       
                    
                        email: ele.email,
                        
                        MainDisposition: ele.MainDisposition,
                       Reason:ele.Reason,
                        Reason_for_Escalation:ele.Reason_for_Escalation
                    })
                    return escalationdata;
                })
                setEscalatedData(escalationdata)
                //console.log(response.data)
                // alert(`file uploaded succesfully`)
            })
            .catch((err) => {
                console.log(err)
            })

    }

    console.log("dailer data ************************",dialerdata)
    return (
        // <div>
             <div className={classes.root}>
   
            
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
         <Typography>Date wise Dialer Report</Typography>
                        
          
        </AccordionSummary>
        <AccordionDetails>
        <InteractionDate getData={getData} setLoader={setLoader} />
            {
                    loader ? (<div>
                        <center>
                            <CircularProgress />
                        </center>
                    </div>
                    ) : (null)
                }
          
                {
                        dialerDateRange.length > 0 && <ExcelReport
                        data={dialerDateRange}
                        fileName={`Dialer Report ${new Date()}`}
                     />
                    }
       
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
         <Typography>Date wise Form Report</Typography>
                        
          
        </AccordionSummary>
        <AccordionDetails>
        <DaterangeReport1 getData={getData1} setLoader={setLoader} />
            {
                    loader1 ? (<div>
                        <center>
                            <CircularProgress />
                        </center>
                    </div>
                    ) : (null)
                }
          
                {
                        formdataRange.length > 0 && <ExcelReport
                        data={formdataRange}
                        fileName={`Form Report ${new Date()}`}
                     />
                    }
       
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
         <Typography>Campaign Report</Typography>
                        
          
        </AccordionSummary>
        <AccordionDetails>
        
        <FormControl variant="outlined" className={classes.formControl} >
                    <InputLabel id="demo-simple-select-outlined-label">Campaign</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={selectedCamapign}
                        onChange={handleChange}
                        label="Campaign"
                        autoWidth="true"
                    >
                        {
                            campaigns.length > 0 ? (
                                campaigns.map((ele) => {
                                    return (<MenuItem value={ele.campaign_name}>{ele.campaign_name}</MenuItem>)
                                })
                            ) : (null)
                        }

                    </Select>
                </FormControl>
                {
                    selectedCamapign &&  <FormControl variant="outlined" className={classes.formControl} >
                    <InputLabel id="demo-simple-select-outlined-label">Report</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={report}

                        onChange={handleChangeReport}
                        label="Report"
                        autoWidth="true"
                    >   
                    <MenuItem value="Dialer_Report">Dialer Report</MenuItem>
                    <MenuItem value="Form_Report">Form Report</MenuItem>
                    <MenuItem value="No_Called_Report">No Called Report</MenuItem>
                        

                    </Select>
                </FormControl>
                }
               
                {
                        downloadData.length > 0 && <ExcelReport
                        data={downloadData}
                        fileName={name}
                     />
                    }
       
        </AccordionDetails>
      </Accordion>
      {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>Daily RequestCallBack Data</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Button variant="contained"
        color="primary"
        className={classes.button} 
        onClick={handleButtonCallBackData}
        endIcon={<SaveAltIcon>send</SaveAltIcon>}> Daily RequestCallBack Data
                        
                    </Button>&nbsp;
                    {
                        requestcallbackdata.length > 0 && <ExcelReport
                        data={requestcallbackdata}
                        fileName={'Callback data'}
                     /> }
        </AccordionDetails>
      </Accordion> */}
            {/* <Grid item lg={3} md={12} xs={12}>
                <Button onClick={handleButtonDailer}> Daily dialer Report
                    <Download DownloadData={dialerdata} />
                </Button>
            </Grid>
            <div>
                <Grid item lg={3} md={12} xs={12}>
                    <Button onClick={handleButtonFormReport}> Daily Form Report
                        <Download DownloadData={formdata} />
                    </Button>
                </Grid>
            </div>
            <div>
                <Grid item lg={3} md={12} xs={12}>
                    <Button onClick={handleButtonescalationReport}> Daily escalated Report
                        <Download DownloadData={escalateddata} />
                    </Button>
                </Grid>
            </div>
            <div>
                <Grid item lg={3} md={12} xs={12}>
                    <Button onClick={handleButtonCallBackData}> Daily RequestCallBack Data
                        <Download DownloadData={requestcallbackdata} />
                    </Button>
                </Grid>
            </div> */}
        </div>

    );
}
export default Report;