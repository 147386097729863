import React, { useState } from 'react'
import {
    Grid,
    Card,
    CardContent,
    CardHeader,

} from '@material-ui/core';
import InteractionDate from './DaterangeReport'

import axios from 'axios';
import moment from 'moment'
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import CircularProgress from '@material-ui/core/CircularProgress'
import { AGENT_SERVICE } from 'src/modules/dashboard-360/utils/endpoints'

const InteractionReport = (props) => {
    const [records, setRecords] = useState([])
    const [setShow] = useState(false)
    const [setCdr] = useState([])
    const [loader, setLoader] = useState(false)

    // const handleClose = () => {
    //     setShow(false);

    // };

    const columns = [

        {
            headerName: 'id',
            field: 'id',
            width: 300
        },

        {
            headerName: 'CALLERNUMBER',
            field: 'CALLERNUMBER',
            width: 200,
        },
        {
            headerName: 'AGENTID',
            field: 'AGENTID',
            width: 300,
        },
        {
            headerName: 'AGENTNAME',
            field: 'AGENTNAME',
            width: 200,
        },
        {
            headerName: 'CALLSTARTTIME',
            field: 'CALLSTARTTIME',
            width: 200,
        },
        
      
        {
            headerName: 'CALLPICKEDTIME',
            field: 'CALLPICKEDTIME',
            width: 200,
        },
        {
            headerName: 'CALLDICONNECTEDTIME',
            field: 'CALLDICONNECTEDTIME',
            width: 200,
        },
        {
            headerName: 'CALLNOANSWERTIME',
            field: 'CALLNOANSWERTIME',
            width: 200,
        },
        {
            headerName: 'CALLDISPOSEDTIME',
            field: 'CALLDISPOSEDTIME',
            width: 200,
        },
        {
            headerName: 'TOTALCALLDURATION',
            field: 'TOTALCALLDURATION',
            width: 200,

        },
        {
            headerName: 'CALLCONNECTEDDURATION',
            field: 'CALLCONNECTEDDURATION',
            width: 200,
        },
        {
            headerName: 'CALLRINGDURATION',
            field: 'CALLRINGDURATION',
            width: 200,
        },

        {
            headerName: 'Queue',
            field: 'Queue',
            width: 200,
        },
        {
            headerName: 'Location',
            field: 'Location',
            width: 200,
        },
       
      

     
        {
            headerName: 'createdAt',
            field: 'createdAt',
            width: 200,
        },
        {
            headerName: 'updatedAt',
            field: 'updatedAt',
            width: 200,
        },
     
       
     
       
        {
            headerName: 'Call Status',
            field: 'Call_Status',
            width: 200,
        },
        {
            headerName: 'mainDisposition',
            field: 'main_Disposition',
            width: 200,
        },
        {
            headerName: 'Request Callback',
            field: 'Request_Callback',
            width: 200,
        },
        {
            headerName: 'Reason',
            field: 'Reason',
            width: 200,
        },
        {
            headerName: 'Escalated',
            field: 'Escalated',
            width: 200,
        },
        {
            headerName: 'Reason for Escalation',
            field: 'Reason_for_Escalation',
            width: 200,
        },
        {
            headerName: 'sip_id',
            field: 'sip_id',
            width: 200,
        },
        {
            headerName: 'agent_type',
            field: 'agent_type',
            width: 200,
        },

        {
            headerName: 'agentName',
            field: 'agentName',
            width: 200,
        },
     
    ]

    const getDataof1 = (data) => {
        console.log(data)
        axios.get(`${AGENT_SERVICE}/interactions/${data.row._id}`)
            .then((res) => {
                console.log(res.data)
                setCdr(res.data)
                setShow(true)
            })
            .catch((err) => {
                console.log(err)
            })
    }



    console.log(loader)
    const getData = (date, enddate) => {
        console.log(date, enddate)
        let value = moment(date).format().slice(0, 10)
        let value2 = moment(enddate).format().slice(0, 10)
        const data = {
            "startDate": value,
            "endDate": value2
        }

        axios.post(`${AGENT_SERVICE}/interactions/bydaterange`, data)
            .then((res) => {
                setLoader(false)
                console.log(res.data.records, "records")
                res.data.records = res.data.records.reverse()
                var i = 0
                res.data.records.map((ele) => {
                    i = i + 1
                    return ele.id = i
                })

                res.data.records.map((ele) => {
                    if (ele.CALLSTARTTIME.length > 1) {
                        var formatted = moment.utc(ele.CALLSTARTTIME * 1000).format('HH:mm:ss');
                        return ele.CALLSTARTTIME = formatted
                    }
                    return res.data.records;
                })
                res.data.records.map((ele) => {
                    if (ele.CALLPICKEDTIME.length > 1) {
                        var formatted = moment.utc(ele.CALLPICKEDTIME * 1000).format('HH:mm:ss');
                        return ele.CALLPICKEDTIME = formatted
                    }
                    return res.data.records;
                })
                res.data.records.map((ele) => {
                    if (ele.CALLDICONNECTEDTIME.length > 1) {
                        var formatted = moment.utc(ele.CALLDICONNECTEDTIME * 1000).format('HH:mm:ss');
                        return ele.CALLDICONNECTEDTIME = formatted
                    }
                    return res.data.records;
                })
                res.data.records.map((ele) => {
                    if (ele.CALLNOANSWERTIME.length > 1) {
                        var formatted = moment.utc(ele.CALLNOANSWERTIME * 1000).format('HH:mm:ss');
                        return ele.CALLNOANSWERTIME = formatted
                    }
                    return res.data.records;
                })

                res.data.records.map((ele) => {
                    if (ele.CALLDISPOSEDTIME.length > 1) {
                        var formatted = moment.utc(ele.CALLDISPOSEDTIME * 1000).format('HH:mm:ss');
                        return ele.CALLDISPOSEDTIME = formatted
                    }
                    return res.data.records;
                })


                res.data.records.map((ele) => {
                    const value = ele.LASTDTMFOPTION.toString()
                    return ele.optionsselct = value
                })
                res.data.records.map((ele) => {
                    const value = moment(ele.createdAt).format('MMMM Do YYYY, h:mm:ss a')
                    return ele.createdAt = value
                })

                res.data.records.map((ele) => {

                    if (ele.hasOwnProperty("CRMDISPOSITION")) {
                        return (
                            ele.sip_id = ele.CRMDISPOSITION.sip_id,
                            ele.agent_type = ele.CRMDISPOSITION.agent_type,
                            ele.agent_id = ele.CRMDISPOSITION.agent_id,
                            ele.agentName = ele.CRMDISPOSITION.agentName,
                            ele.Call_Status=ele.CRMDISPOSITION.Call_Status,
                     ele.main_Disposition = ele.CRMDISPOSITION.main_Disposition,
                    ele.Request_Callback=ele.CRMDISPOSITION.Request_Callback,
                    ele.Reason=ele.CRMDISPOSITION.Reason,
                    ele.Escalated=ele.CRMDISPOSITION.Escalated,
                    ele.Reason_for_Escalation=ele.CRMDISPOSITION.Reason_for_Escalation
                         
                           
                      
                        )
                    } else {
                        return (
                            ele.sip_id = '',
                            ele.agent_type = '',
                            ele.agent_id = '',
                            ele.agentName = '',
                            ele.Call_Status='',
                            ele.main_Disposition = '',
                           ele.Request_Callback='',
                           ele.Reason='',
                           ele.Escalated='',
                           ele.Reason_for_Escalation=''
                        )
                    }
                })


                setRecords(res.data.records)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    // if (records.length > 0) {
    //     const records1 = records.map((ele) => {
    //         return ele.CALLSTARTTIME === new Date(ele.CALLSTARTTIME)
    //     })
    //     setRecords(records1)
    // }


    return (<div>
        <br />
        <br />
        <Grid container spacing={3} direction="row">
            <Grid item xs={6} sm={6} lg={5}></Grid>
            <Grid item xs={6} sm={6} lg={4}><h3>Interaction Performance Report</h3></Grid>
            <Grid item xs={6} sm={6} lg={3}></Grid>
        </Grid>
        <Grid container spacing={3} direction="row">
            <Grid item xs={6} sm={6} lg={5}></Grid>
            <Grid item xs={12} sm={6} lg={12}> <center><InteractionDate getData={getData} setLoader={setLoader} /></center></Grid>
            <Grid item xs={6} sm={6} lg={1}></Grid>
            <Grid item xs={6} sm={6} lg={2}></Grid>
            <Grid item xs={6} sm={6} lg={5}></Grid>
            <Grid item xs={4} sm={4} lg={12}>
                {
                    loader ? (<div>
                        <center>
                            <CircularProgress />
                        </center>
                    </div>
                    ) : (null)
                }
            </Grid>
            {/* <Grid item xs={6} sm={6} lg={5}></Grid> */}
        </Grid>
        <Grid container spacing={3} direction="row">
            {
                records.length > 0 ? (
                    <div style={{ height: '1000px', width: "100%" }}>
                        <Card >
                            <CardHeader
                                title={
                                    `Interaction Records`
                                }
                            />
                            <CardContent style={{ 'height': '500px' }}>
                                <DataGrid components={{
                                    Toolbar: GridToolbar,
                                }} rows={records} columns={columns} pageSize={20}
                                    pagination
                                    rowsPerPageOptions={[20, 40, 100]}
                                    onRowClick={getDataof1}
                                    height='400px' />
                            </CardContent>
                        </Card>
                    </div>
                ) : (null)
            }

        </Grid>
    </div>)
}

export default InteractionReport